import React from 'react';
import {gql, useQuery} from '@apollo/client';

import {Loading} from '../utils/LoadingBoundary';
import {DataError} from '../utils/DataErrorBoundary';

// Get a bunch of commonly used fields here to avoid firing off other queries
// immediately
const viewerQuery = gql`
  query Viewer {
    viewer {
      id
      type
      email
      unreadConversationCount
      userAttentionRequired {
        type
        offerRequest {
          id
          location {
            displayName
          }
        }
        order {
          id
          offerRequest {
            id
          }
          workAddress
        }
      }
      admin {
        id
      }
      client {
        id
      }
      contractor {
        id
      }
    }
  }
`;

const withPerspective = (WrappedComponent) => (props) => {
  const {loading, error, data} = useQuery(viewerQuery);
  if (loading) return <Loading />;
  if (error) return <DataError error={error} />;

  const {viewer} = data;

  return (
    <WrappedComponent
      perspective={viewer ? viewer.type : null}
      userAttentionRequired={viewer ? viewer.userAttentionRequired : null}
      {...props}
    />
  );
};

const useCurrentUser = () => {
  const {data, loading, error} = useQuery(viewerQuery);

  if (loading) return <Loading />;
  if (error) return <DataError error={error} />;

  return data ? data.viewer : null;
};

export default withPerspective;
export {viewerQuery, useCurrentUser};
