import React from 'react';
import {Navigate} from 'react-router-dom';

import {urls} from './utils/localization';
import {lazyLoadRoute} from './utils/routeUtils';

const clientRoutes = [
  {path: `/`, element: <Navigate replace to={urls.offerRequests} />, end: true},
  {
    path: urls.offerRequests,
    element: lazyLoadRoute('ClientOfferRequests'),
    caseSensitive: true,
  },
  {
    path: `${urls.offerRequests}/:offerRequestId${urls.contractors}/:contractorId`,
    element: lazyLoadRoute('ViewOfferRequestContractor'),
  },
  {
    path: `${urls.offerRequests}/:offerRequestId${urls.messages}/:contractorId`,
    element: lazyLoadRoute('Conversation'),
  },
  {
    path: `${urls.offerRequests}/:offerRequestId${urls.offers}/:offerId`,
    element: lazyLoadRoute('ClientOfferRedirect'),
  },
  {
    path: `${urls.offerRequests}${urls.new}`,
    element: lazyLoadRoute('NewOfferRequest'),
  },
  {
    path: `${urls.offerRequests}${urls.thankYou}`,
    element: lazyLoadRoute('ThankYouOfferRequest'),
  },
  {
    path: `${urls.offerRequests}/:id${urls.edit}`,
    element: lazyLoadRoute('EditOfferRequest'),
  },
  {
    path: `${urls.offerRequests}/:id`,
    element: lazyLoadRoute('ClientViewOfferRequest'),
  },
  {path: urls.messages, element: lazyLoadRoute('ClientConversations')},
  {path: urls.profile, element: lazyLoadRoute('ClientProfile')},
  {path: `${urls.review}/:orderId`, element: lazyLoadRoute('Review')},
  {
    path: `${urls.review}/:orderId${urls.thankYou}`,
    element: lazyLoadRoute('ThankYouReview'),
  },
  {
    path: `*`,
    element: lazyLoadRoute('NotFound'),
    end: false,
    caseSensitive: false,
  },
];

export {clientRoutes};
