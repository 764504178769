import {useEffect} from 'react';

const useOnClickOutside = (allowedParentRef, handler, condition = true) => {
  useEffect(() => {
    if (!condition) {
      return;
    }

    const clickHandler = (e) => {
      if (
        !allowedParentRef.current ||
        allowedParentRef.current.contains(e.target)
      ) {
        return;
      }

      handler(e);
    };

    document.addEventListener('mousedown', clickHandler);
    document.addEventListener('touchstart', clickHandler);

    return () => {
      document.removeEventListener('mousedown', clickHandler);
      document.removeEventListener('touchstart', clickHandler);
    };
  }, [allowedParentRef, handler, condition]);
};

export default useOnClickOutside;
