import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
  createContext,
} from 'react';
import {v4 as uuidv4} from 'uuid';

const LOADING_DELAY_MS = 1000;

const LoadingContext = createContext([() => {}, () => {}]);

const Loading = ({children = null}) => {
  const [startLoading, stopLoading] = useContext(LoadingContext);

  useEffect(() => {
    const loadingId = uuidv4();
    startLoading(loadingId);
    return () => stopLoading(loadingId);
  }, [startLoading, stopLoading]);

  return children;
};

const LoadingBoundary = ({display = () => null, children}) => {
  const [loadingSet] = useState(new Set());
  const [isLoading, setIsLoading] = useState(false);
  const loadingTimeout = useRef(null);

  const startLoading = (key) => {
    loadingSet.add(key);
    if (loadingSet.size > 0 && !loadingTimeout.current) {
      loadingTimeout.current = setTimeout(
        () => setIsLoading(true),
        LOADING_DELAY_MS
      );
    }
  };
  const stopLoading = (key) => {
    loadingSet.delete(key);
    if (loadingSet.size === 0) {
      if (loadingTimeout.current) {
        clearTimeout(loadingTimeout.current);
        loadingTimeout.current = null;
      }
      setIsLoading(false);
    }
  };
  const contextValue = useMemo(
    () => [startLoading, stopLoading], // eslint-disable-next-line
    [
      /*startLoading, stopLoading*/
    ]
  );

  const displayRender = isLoading ? display() : null;
  const childrenIsFunction = typeof children === 'function';

  return (
    <>
      {!childrenIsFunction && displayRender}
      <LoadingContext.Provider value={contextValue}>
        {childrenIsFunction ? children(displayRender) : children}
      </LoadingContext.Provider>
    </>
  );
};

export default LoadingBoundary;
export {Loading};
