import flow from 'lodash/flow';
import {isValid as dateIsValid, formatISO, parseISO} from 'date-fns';

import {
  formatNumber,
  parseNumber,
  formatPrice,
  parsePrice,
  formatPercentage,
  parsePercentage,
} from './formatters';
import removeTypenames from './removeTypenames';

const serializeDate = (date, options = {representation: 'complete'}) => {
  if (!date) return null;
  try {
    return formatISO(date, options);
  } catch (_) {}
  return null;
};

const deserializeDate = (val) => {
  if (!val) return null;
  const parsed = parseISO(val);
  if (!dateIsValid(parsed)) return null;
  return parsed;
};

const formatPriceObj = flow([
  removeTypenames,
  ({
    taxRate,
    withoutTax,
    withTax,
    laborWithoutTax,
    laborWithTax,
    laborPercent,
    quantityEstimate,
    ...rest
  }) => ({
    taxRate: formatPercentage(taxRate),
    withoutTax: formatPrice(withoutTax),
    withTax: formatPrice(withTax),
    laborWithoutTax: formatPrice(laborWithoutTax),
    laborWithTax: formatPrice(laborWithTax),
    laborPercent: formatPercentage(laborPercent),
    quantityEstimate: formatNumber(quantityEstimate),
    ...rest,
  }),
]);

const parsePriceObj = ({
  taxRate,
  withoutTax,
  withTax,
  laborWithoutTax,
  laborWithTax,
  laborPercent,
  quantityEstimate,
  ...rest
}) => ({
  taxRate: parsePercentage(taxRate),
  withoutTax: parsePrice(withoutTax),
  withTax: parsePrice(withTax),
  laborWithoutTax: parsePrice(laborWithoutTax),
  laborWithTax: parsePrice(laborWithTax),
  laborPercent: parsePercentage(laborPercent),
  quantityEstimate: parseNumber(quantityEstimate),
  ...rest,
});

const formatParameterValue = flow([
  removeTypenames,
  ({floatValue, ...rest}) => {
    return {
      floatValue: floatValue ? formatNumber(floatValue) : null,
      ...rest,
    };
  },
]);

const parseParameterValue = ({floatValue, ...rest}) => {
  const floatValueParsed = parseNumber(floatValue);
  return {
    floatValue: isFinite(floatValueParsed) ? floatValueParsed : null,
    ...rest,
  };
};

export {
  serializeDate,
  deserializeDate,
  formatPriceObj,
  parsePriceObj,
  formatParameterValue,
  parseParameterValue,
};
