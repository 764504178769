import styled, {css} from 'styled-components/macro';
import {colorStyle, space} from 'styled-system';
import {themeGet} from '@styled-system/theme-get';

const BoxListItem = styled.div`
  display: ${(props) => (props.flex ? 'flex' : 'block')};
  ${(props) => props.alignItems && `align-items: ${props.alignItems};`};
  flex-direction: ${(props) => (props.flexdirection ? 'column' : 'row')};

  width: 100%;
  padding: 13px ${themeGet('space.7')};

  border-bottom: 2px solid ${themeGet('colors.highlight3Light')};

  text-decoration: none;

  background-color: transparent;
  color: ${themeGet('colors.text')};

  transition: background ${themeGet('transitions.fast')};

  @media screen and (min-width: ${themeGet('breakpoints.md')}) {
    ${(props) =>
      props.flexdirection &&
      `flex-direction: row;
      justify-content: space-between;`}

    padding: ${(props) =>
      props.size === 'thin'
        ? css`
            ${themeGet('space.5')}
          `
        : css`
            ${themeGet('space.8')}
          `};
  }

  &:hover {
    background-color: ${themeGet('colors.bgActive')};
  }
  &:active {
    background: ${themeGet('colors.bgActive')};
  }

  ${colorStyle}
  ${space}
`;

export default BoxListItem;
