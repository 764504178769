import {Component} from 'react';
import {captureException, withScope as withSentryScope} from '@sentry/browser';

class ScriptErrorBoundary extends Component {
  state = {error: null};

  static getDerivedStateFromError(error) {
    return {error};
  }

  componentDidCatch(error, info) {
    console.error(error);
    withSentryScope((scope) => {
      scope.setExtras(info);
      captureException(error);
    });
  }

  render() {
    if (this.state.error !== null) {
      return this.props.display(this.state.error);
    }

    return this.props.children;
  }
}

export default ScriptErrorBoundary;
