import {useEffect} from 'react';
import {gql, useQuery} from '@apollo/client';
import {captureException} from '@sentry/browser';
import TagManager from 'react-gtm-module';

const SessionDataPublisherQuery = gql`
  query SessionDataPublisher {
    viewer {
      id
      type
    }
  }
`;

const SessionDataPublisher = () => {
  const result = useQuery(SessionDataPublisherQuery);

  const data = result?.data || null;
  const error = result?.error || null;
  const viewer = data?.viewer || null;
  const userId = viewer?.id || null;
  const userType = viewer?.type || null;

  useEffect(() => {
    if (error !== null) {
      console.error(error);
      captureException(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error === null]);

  useEffect(() => {
    if (data === null) return;

    // Google Tag Manager
    if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
      try {
        TagManager.dataLayer({
          dataLayer: {
            event: 'userDataUpdate',
            userId,
            userType,
          },
        });
      } catch (error) {
        console.error(error);
        captureException(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data === null, userId]);

  return null;
};

export default SessionDataPublisher;
