import {useContext, createContext} from 'react';

const NAV_BREAKPOINT = '(min-width: 820px)';
const NAV_MINI_BREAKPOINT = '(max-width: 350px), (max-height: 500px)';

const MenuStateContext = createContext({
  menuCanOpen: false,
  menuIsOpen: false,
});

const useMenuState = () => useContext(MenuStateContext);
const MenuStateProvider = MenuStateContext.Provider;

export {NAV_BREAKPOINT, NAV_MINI_BREAKPOINT, useMenuState, MenuStateProvider};
