import styled from 'styled-components/macro';
import {space} from 'styled-system';
import {themeGet} from '@styled-system/theme-get';

const ContentContainer = styled.div`
  margin-left: 5vw;
  margin-right: 5vw;

  @media screen and (min-width: ${themeGet('breakpoints.sm')}) {
    width: 540px;
    max-width: 540px;

    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: ${themeGet('breakpoints.md')}) {
    width: ${({type}) =>
      type === 'wide'
        ? '960px'
        : type === 'extraWide'
        ? '1200px'
        : type === 'edgeToEdge'
        ? '100%'
        : '720px'};
    max-width: ${({type}) => (type === 'edgeToEdge' ? '95vw' : '720px')};
  }

  @media screen and (min-width: ${themeGet('breakpoints.lg')}) {
    max-width: ${({type}) => (type === 'edgeToEdge' ? '95vw' : '960px')};
  }

  @media screen and (min-width: ${themeGet('breakpoints.xl')}) {
    max-width: ${({type}) => (type === 'edgeToEdge' ? '95vw' : '1140px')};
  }

  ${space}
`;

export default ContentContainer;
