import {
  isValid as dateIsValid,
  format as dateFormat,
  formatDistance as dateFormatDistance,
  differenceInHours,
  differenceInCalendarDays,
  isSameYear,
} from 'date-fns';
import dateFnsLocaleFi from 'date-fns/locale/fi';

const parseNumber = (val) => {
  if (typeof val === 'undefined' || val === null) return NaN;
  return parseFloat(val.toString().replace(/\s+/g, '').replace(/,/g, '.'));
};

const parsePrice = (val) => Math.round(100 * parseNumber(val));

const parsePercentage = parsePrice;

const reformatNumber = (val) => {
  const parts = val.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join(',');
};

const formatNumber = (val) => {
  if (typeof val !== 'number' || !isFinite(val)) return '';
  return reformatNumber(val.toString());
};

const formatPrice = (val, decimals = 2) => {
  if (typeof val !== 'number' || !isFinite(val)) return '';
  val /= 100;
  if (typeof val.toFixed === 'function') {
    val = val.toFixed(decimals);
  } else {
    val = val.toString();
  }
  return reformatNumber(val);
};

const formatPercentage = (val) => {
  if (typeof val !== 'number' || !isFinite(val)) return '';
  return reformatNumber((val / 100).toString());
};

const getNumberCategory = (val) => {
  if (val === 0) return 'zero';
  if (val === 1) return 'one';
  return 'other';
};

const formatQuantity = (val, options) => {
  if (typeof val !== 'number' || !isFinite(val)) return '';
  let category = getNumberCategory(val);
  if (!(`${category}Only` in options) && !(category in options)) {
    category = 'other';
  }
  if (`${category}Only` in options) {
    return options[`${category}Only`];
  }
  const formattedVal = formatNumber(val);
  if (typeof options[category] === 'function') {
    return options[category](formattedVal);
  }
  return formattedVal + options[category];
};

const isValidDate = (val) => {
  if (!(val instanceof Date)) return false;
  return dateIsValid(val);
};

const formatDate = (val) => {
  if (!isValidDate(val)) return '';
  return dateFormat(val, 'd.M.yyyy', {locale: dateFnsLocaleFi});
};

const formatDateTime = (val) => {
  if (!isValidDate(val)) return '';
  return dateFormat(val, "d.M.yyyy 'klo' H.mm", {locale: dateFnsLocaleFi});
};

const formatRelativeDateTime = (val, nowVal = undefined) => {
  if (!isValidDate(val)) return '';
  if (!nowVal) nowVal = new Date();

  if (Math.abs(differenceInHours(nowVal, val)) <= 4) {
    return dateFormatDistance(val, nowVal, {
      addSuffix: true,
      locale: dateFnsLocaleFi,
    });
  }
  const diffDays = differenceInCalendarDays(nowVal, val);
  if (diffDays === -1) {
    return dateFormat(val, "'huomenna klo' H.mm", {locale: dateFnsLocaleFi});
  }
  if (diffDays === 0) {
    return dateFormat(val, "'tänään klo' H.mm", {locale: dateFnsLocaleFi});
  }
  if (diffDays === 1) {
    return dateFormat(val, "'eilen klo' H.mm", {locale: dateFnsLocaleFi});
  }
  if (isSameYear(val, nowVal)) {
    return dateFormat(val, "d.M. 'klo' H.mm", {locale: dateFnsLocaleFi});
  }
  return dateFormat(val, "d.M.yyyy 'klo' H.mm", {locale: dateFnsLocaleFi});
};

const formatRelativeDateTimeShort = (val, nowVal = undefined) => {
  if (!isValidDate(val)) return '';
  if (!nowVal) nowVal = new Date();

  const diffDays = differenceInCalendarDays(nowVal, val);
  if (diffDays === 0) {
    return dateFormat(val, "'klo' H.mm", {locale: dateFnsLocaleFi});
  }
  if (diffDays === -1) {
    return 'huomenna';
  }
  if (diffDays === 0) {
    return 'tänään';
  }
  if (diffDays === 1) {
    return 'eilen';
  }
  if (isSameYear(val, nowVal)) {
    return dateFormat(val, 'd.M.', {locale: dateFnsLocaleFi});
  }
  return dateFormat(val, 'd.M.yyyy', {locale: dateFnsLocaleFi});
};

const formatRelativeDate = (val, nowVal = undefined) => {
  if (!isValidDate(val)) return '';
  if (!nowVal) nowVal = new Date();

  const diffDays = differenceInCalendarDays(nowVal, val);
  if (diffDays === -1) {
    return 'huomenna';
  }
  if (diffDays === 0) {
    return 'tänään';
  }
  if (diffDays === 1) {
    return 'eilen';
  }
  if (isSameYear(val, nowVal)) {
    return dateFormat(val, 'd.M.', {locale: dateFnsLocaleFi});
  }
  return dateFormat(val, 'd.M.yyyy', {locale: dateFnsLocaleFi});
};

const formatDaysAgo = (val, nowVal = undefined) => {
  if (!isValidDate(val)) return '';
  if (!nowVal) nowVal = new Date();

  return differenceInCalendarDays(nowVal, val);
};

const firstLowerCase = (val) => {
  if (typeof val === 'undefined' || val === null) return '';
  const str = val.toString();
  if (str.length === 0) return '';
  return str[0].toLowerCase() + str.substring(1);
};

const firstUpperCase = (val) => {
  if (typeof val === 'undefined' || val === null) return '';
  const str = val.toString();
  if (str.length === 0) return '';
  return str[0].toUpperCase() + str.substring(1);
};

const partition = (arr, filter) =>
  arr.reduce(
    (r, e) => {
      filter(e) ? r[0].push(e) : r[1].push(e);
      return r;
    },
    [[], []]
  );

const camelize = (str) => {
  const umlauts = {ä: 'a', ö: 'o', å: 'a'};

  return str
    .toLowerCase()
    .replace(/ä|ö|å/g, (umlaut) => umlauts[umlaut])
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

const formatTelLink = (val) => {
  if (typeof val === 'undefined' || val === null) return '';
  const str = val.toString();
  if (str.length === 0) return '';
  return val.replace(/[^\d+]/g, '');
};

const normalizeBusinessId = (val) => {
  if (!val || val.length === 0) return '';
  val = val.trim();
  if (!val.includes('-')) {
    const beforeHyphen = val.slice(0, val.length - 1);
    const afterHyphen = val.slice(-1);
    val = `${beforeHyphen}-${afterHyphen}`;
  }
  return val;
};

export {
  parseNumber,
  parsePrice,
  parsePercentage,
  reformatNumber,
  formatNumber,
  formatPrice,
  formatPercentage,
  formatQuantity,
  formatDate,
  formatDateTime,
  formatRelativeDateTime,
  formatRelativeDateTimeShort,
  formatRelativeDate,
  formatDaysAgo,
  firstLowerCase,
  firstUpperCase,
  partition,
  camelize,
  formatTelLink,
  normalizeBusinessId,
};
