import styled from 'styled-components/macro';
import {buttonStyle, space} from 'styled-system';
import {themeGet} from '@styled-system/theme-get';

const Button = styled.button.attrs(({disabled}) =>
  disabled ? {variant: 'inactive'} : {}
)`
  display: inline-block;
  border-radius: 5px;
  padding: ${themeGet('space.6')};

  text-align: center;
  line-height: 1;
  text-decoration: none;

  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  ${buttonStyle}
  ${space}
`;

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
};

export default Button;
