import React from 'react';
import {Navigate} from 'react-router-dom';

import {urls} from './utils/localization';
import {lazyLoadRoute} from './utils/routeUtils';

const guestRoutes = [
  {
    path: `${urls.offerRequests}${urls.new}`,
    element: lazyLoadRoute('NewOfferRequest'),
  },
  {
    path: `${urls.offerRequests}${urls.thankYou}`,
    element: lazyLoadRoute('ThankYouOfferRequest'),
  },
  {path: `${urls.joinAsPro}`, element: lazyLoadRoute('JoinAsPro')},
  {path: `*`, element: <Navigate replace to={urls.login} />},
];

export {guestRoutes};
