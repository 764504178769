import React from 'react';
import {Navigate, useParams} from 'react-router-dom';

import {urls} from './utils/localization';
import WhenIdle from './utils/WhenIdle';
import Intercom from './utils/Intercom';

import {lazyLoadRoute} from './utils/routeUtils';

const LegacyNavigateToContractorViewOfferRequest = () => {
  const {id} = useParams();
  return <Navigate replace to={`${urls.offerRequests}/${id}`} />;
};

const contractorRoutes = [
  {
    element: (
      <WhenIdle>
        <Intercom />
      </WhenIdle>
    ),
  },
  {path: `/`, element: <Navigate replace to={urls.offerRequests} />, end: true},
  {
    path: `${urls.offerRequests}${urls.new}`,
    element: <Navigate replace to={urls.offerRequests} />,
  },
  {
    path: urls.offerRequests,
    element: lazyLoadRoute('ContractorOfferRequests'),
  },
  {
    path: `${urls.offerRequests}/:offerRequestId${urls.messages}`,
    element: lazyLoadRoute('Conversation'),
  },
  {
    path: `${urls.offerRequests}/:id`,
    element: lazyLoadRoute('ContractorViewOfferRequest'),
  },
  {
    path: `${urls.offerRequests}/:id${urls.offers}/*`,
    element: <LegacyNavigateToContractorViewOfferRequest />,
  },
  {path: urls.offerTemplates, element: lazyLoadRoute('OfferTemplates')},
  {
    path: `${urls.offerTemplates}${urls.new}`,
    element: lazyLoadRoute('NewOfferTemplate'),
  },
  {
    path: `${urls.offerTemplates}/:id`,
    element: lazyLoadRoute('EditOfferTemplate'),
  },
  {path: urls.messages, element: lazyLoadRoute('ContractorConversations')},
  {path: urls.messageTemplates, element: lazyLoadRoute('MessageTemplates')},
  {
    path: `${urls.messageTemplates}${urls.new}`,
    element: lazyLoadRoute('NewMessageTemplate'),
  },
  {
    path: `${urls.messageTemplates}/:id`,
    element: lazyLoadRoute('EditMessageTemplate'),
  },
  {path: urls.reviews, element: lazyLoadRoute('ContractorReviews')},
  {path: urls.support, element: lazyLoadRoute('ContractorSupport')},
  {path: urls.serviceFeeInvoices, element: lazyLoadRoute('ContractorServiceFeeInvoices')},
  {path: urls.profile, element: lazyLoadRoute('ContractorProfile')},
  {
    path: `*`,
    element: lazyLoadRoute('NotFound'),
    end: false,
    caseSensitive: false,
  },
];

export {contractorRoutes};
