import styled from 'styled-components/macro';
import {themeGet} from '@styled-system/theme-get';

const HeaderBadge = styled.div`
  display: inline-block;

  min-width: 1.4em;
  padding: 0.2em 0.6em;

  border-radius: 10em;

  text-align: center;

  font-size: calc(${themeGet('fontSizes.0')} + 0.1rem);
  font-weight: 700;
  letter-spacing: 0.05em;

  background: ${themeGet('colors.primary')};
  color: ${themeGet('colors.bg')};

  transition: background-color ${themeGet('transitions.fast')};
`;

export default HeaderBadge;
