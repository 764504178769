import React from 'react';
import {Link as ReactRouterLink} from 'react-router-dom';

const Link = ({onClick, scrollToTop = false, ...rest}) => {
  const handleClick = (...args) => {
    if (typeof onClick === 'function') onClick(...args);

    if (scrollToTop) {
      // Scroll to top on navigation
      setTimeout(() => window.scrollTo(0, 0), 0);
    }
  };

  return <ReactRouterLink onClick={handleClick} {...rest} />;
};

export default Link;
