import React from 'react';
import {NavLink as ReactRouterNavLink} from 'react-router-dom';

const NavLink = ({
  to,
  matchedQueryParams,
  onClick,
  scrollToTop = true,
  ...rest
}) => {
  const handleClick = (...args) => {
    if (typeof onClick === 'function') onClick(...args);

    if (scrollToTop) {
      // Scroll to top on navigation
      setTimeout(() => window.scrollTo(0, 0), 0);
    }
  };

  return <ReactRouterNavLink onClick={handleClick} to={to} {...rest} />;
};

export default NavLink;
