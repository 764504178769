import React from 'react';
import {createRoot} from 'react-dom/client';
import * as Sentry from '@sentry/browser';
import dateFnsLocaleFi from 'date-fns/locale/fi';
import {
  registerLocale as reactDatepickerRegisterLocale,
  setDefaultLocale as reactDatePickerSetDefaultLocale,
} from 'react-datepicker';
import TagManager from 'react-gtm-module';

import App from './App';

// Initialize error reporting
if (process.env.REACT_APP_SENTRY_DSN) {
  const sentryConfig = {
    dsn: process.env.REACT_APP_SENTRY_DSN,
  };
  if (process.env.REACT_APP_SENTRY_ENVIRONMENT) {
    sentryConfig.environment = process.env.REACT_APP_SENTRY_ENVIRONMENT;
  }
  if (process.env.REACT_APP_SENTRY_SESSION_REPLAY === 'enabled') {
    sentryConfig.replaysSessionSampleRate = 0;
    sentryConfig.replaysOnErrorSampleRate = 1.0;
    sentryConfig.integrations = [
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ];
  }
  Sentry.init(sentryConfig);
}

// Configure default locales
reactDatepickerRegisterLocale('fi', dateFnsLocaleFi);
reactDatePickerSetDefaultLocale('fi');

// Initialize Google Tag Manager
const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
if (gtmId) {
  TagManager.initialize({gtmId});
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App tab="home" />);
