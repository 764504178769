import * as Sentry from '@sentry/browser';

const SHOULD_CHECK_ON_WRITE = false;

const logWarning = (message) => {
  console.warn(message);
  try {
    Sentry.captureMessage(message);
  } catch (_) {}
};

const memoryStorage = new Map();

const readFromStorage = (key, treatEmptyStringAsMissing = false) => {
  const memoryValue = memoryStorage.get(key) ?? null;
  const localStorageValue = window.localStorage.getItem(key) ?? null;

  if (memoryValue !== null) {
    if (localStorageValue === null) {
      // logWarning(
      //   `Reading: memory value for key was present, but local storage value was null`
      // );
      window.localStorage.setItem(key, memoryValue);
      return memoryValue;
    } else if (localStorageValue !== memoryValue) {
      if (localStorageValue === '' && treatEmptyStringAsMissing) {
        // logWarning(
        //   `Reading: memory value for key was present, but local storage value was empty string`
        // );
        window.localStorage.setItem(key, memoryValue);
        return memoryValue;
      }
      // logWarning(
      //   `Reading: memory value for key was present, but local storage value was different`
      // );
      memoryStorage.set(key, localStorageValue);
      return localStorageValue;
    }
    // Both present and match
    return memoryValue;
  } else {
    if (localStorageValue !== null) {
      memoryStorage.set(key, localStorageValue);
      return localStorageValue;
    }
  }
  return null;
};

const writeToStorage = (key, value, treatEmptyStringAsMissing = false) => {
  if (SHOULD_CHECK_ON_WRITE) {
    const memoryValue = memoryStorage.get(key) ?? null;
    const localStorageValue = window.localStorage.getItem(key) ?? null;

    if (memoryValue !== null) {
      if (localStorageValue === null) {
        logWarning(
          `Writing: memory value for key was present, but local storage value was null`
        );
      } else if (localStorageValue !== memoryValue) {
        if (localStorageValue === '' && treatEmptyStringAsMissing) {
          logWarning(
            `Writing: memory value for key was present, but local storage value was empty string`
          );
        } else {
          logWarning(
            `Writing: memory value for key was present, but local storage value was different`
          );
        }
      }
    }
  }

  memoryStorage.set(key, value);
  window.localStorage.setItem(key, value);
};

const deleteFromStorage = (key, treatEmptyStringAsMissing = false) => {
  if (SHOULD_CHECK_ON_WRITE) {
    const memoryValue = memoryStorage.get(key) ?? null;
    const localStorageValue = window.localStorage.getItem(key) ?? null;

    if (memoryValue !== null) {
      if (localStorageValue === null) {
        logWarning(
          `Deleting: memory value for key was present, but local storage value was null`
        );
      } else if (localStorageValue !== memoryValue) {
        if (localStorageValue === '' && treatEmptyStringAsMissing) {
          logWarning(
            `Deleting: memory value for key was present, but local storage value was empty string`
          );
        } else {
          logWarning(
            `Deleting: memory value for key was present, but local storage value was different`
          );
        }
      }
    }
  }

  memoryStorage.delete(key);
  window.localStorage.removeItem(key);
};

const clearStorage = () => {
  memoryStorage.clear();
  window.localStorage.clear();
};

export {readFromStorage, writeToStorage, deleteFromStorage, clearStorage};
