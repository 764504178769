import React from 'react';
import styled, {ThemeConsumer} from 'styled-components/macro';
import {themeGet} from '@styled-system/theme-get';
import PulseLoader from 'react-spinners/PulseLoader';

const StyledLoadingDisplay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;

  pointer-events: none;

  display: flex;
  align-items: center;
  justify-content: center;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: ${themeGet('transitions.medium')} fadeIn;
`;

const IndicatorWrapper = styled.div`
  padding: ${themeGet('space.8')} ${themeGet('space.9')};
  padding-top: calc(${themeGet('space.8')} + 6px);

  border-radius: 999px;

  background: rgba(255, 255, 255, 0.5);
`;

const LoadingDisplay = () => (
  <StyledLoadingDisplay>
    <IndicatorWrapper>
      <ThemeConsumer>
        {(theme) => <PulseLoader color={theme.colors.text} />}
      </ThemeConsumer>
    </IndicatorWrapper>
  </StyledLoadingDisplay>
);

export default LoadingDisplay;
