import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {gql, useApolloClient, useMutation} from '@apollo/client';
import {captureException, captureMessage} from '@sentry/browser';

import {urls} from '../utils/localization';
import {setSessionToken} from '../utils/session';
import {
  readFromStorage,
  writeToStorage,
  deleteFromStorage,
} from '../utils/storage';
import {Loading} from '../utils/LoadingBoundary';

const LoginMutation = gql`
  mutation Login($token: String!) {
    login(token: $token) {
      sessionToken
      redirectUrl
    }
  }
`;

const logError = (message) => {
  captureMessage(message);
};

const storageWorks = () => {
  try {
    writeToStorage('udStorageTestKey', 'udStorageTestValue');
    if (readFromStorage('udStorageTestKey') !== 'udStorageTestValue') {
      logError('Storage check failed');
      return false;
    }
    deleteFromStorage('udStorageTestKey');
  } catch (error) {
    captureException(error);
    return false;
  }
  return true;
};

const HandleLogin = ({children}) => {
  const client = useApolloClient();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const [login, {loading}] = useMutation(LoginMutation, {
    variables: {token},
    onCompleted: ({login: loginData}) => {
      const receivedSessionToken = loginData?.sessionToken || null;
      setSessionToken(receivedSessionToken);

      const redirectUrl = loginData?.redirectUrl || null;
      const redirectQueryParam = searchParams.get('redirect');
      const effectiveRedirectUrl =
        redirectQueryParam || redirectUrl || urls.offerRequests;

      client.cache.reset();

      navigate(effectiveRedirectUrl, {replace: true});
    },
  });

  useEffect(() => {
    storageWorks && token && login();
  }, [token, login]);

  if (loading) return <Loading />;

  return children;
};

export default HandleLogin;
