import React from 'react';
import styled from 'styled-components/macro';
import {themeGet} from '@styled-system/theme-get';
import {IoIosArrowDown, IoIosArrowUp} from 'react-icons/io';
import {gql, useQuery} from '@apollo/client';
import {useLocation} from 'react-router-dom';

import {Loading} from '../utils/LoadingBoundary';
import {DataError} from '../utils/DataErrorBoundary';
import {formatNumber} from '../utils/formatters';
import {urls} from '../utils/localization';
import {NAV_BREAKPOINT, NAV_MINI_BREAKPOINT, useMenuState} from './headerUtils';
import HeaderBadge from './HeaderBadge';

import {ReactComponent as LogoIconSvg} from './LogoIcon.svg';

const StyledMenuIndicator = styled.div`
  position: relative;
  bottom: 2px;

  margin-left: calc(${themeGet('space.6')} - 2px);
`;

const MenuIndicator = () => {
  const {menuCanOpen, menuIsOpen} = useMenuState();

  if (!menuCanOpen) return null;

  return (
    <StyledMenuIndicator>
      {!menuIsOpen && <IoIosArrowDown />}
      {menuIsOpen && <IoIosArrowUp />}
    </StyledMenuIndicator>
  );
};

const StyledLogoBadge = styled(HeaderBadge)`
  animation: attention 1.2s linear infinite;

  margin-left: calc(${themeGet('space.6')} - 2px);

  @media ${NAV_BREAKPOINT} {
    display: none;
  }

  @keyframes attention {
    50% {
      background-color: ${themeGet('colors.brandBlue')};
    }
  }
`;

const LogoBadgeQuery = gql`
  query LogoBadge {
    viewer {
      id
      unreadConversationCount
    }
  }
`;

const LogoBadge = ({...rest}) => {
  const {menuIsOpen} = useMenuState();
  const location = useLocation();

  const {loading, error, data} = useQuery(LogoBadgeQuery);

  if (loading) return <Loading />;
  if (error) return <DataError error={error} />;
  const {viewer} = data;

  if (menuIsOpen) return null;
  if (location.pathname === urls.messages) return null;
  if (!viewer) return null;
  const {unreadConversationCount} = viewer;
  if (!unreadConversationCount) return null;

  return (
    <StyledLogoBadge {...rest}>
      {formatNumber(unreadConversationCount)} lukematta
    </StyledLogoBadge>
  );
};

const StyledLogoIcon = styled.div`
  width: 65px;
  height: 65px;
  margin: 0 ${themeGet('space.7')};
  margin-left: calc(env(safe-area-inset-left) + ${themeGet('space.7')});

  background: ${themeGet('colors.secondary')};

  display: flex;
  justify-content: center;
  align-items: center;

  @media ${NAV_MINI_BREAKPOINT} {
    width: 55px;
    height: 55px;
  }

  > svg {
    /* Visually center logo */
    position: relative;
    left: 1.5%;
    height: 25.78px;
    width: 41px;

    @media ${NAV_MINI_BREAKPOINT} {
      width: 34.64px;
      height: 21.77px;
    }
  }
`;

const LogoIcon = () => (
  <StyledLogoIcon>
    <LogoIconSvg />
  </StyledLogoIcon>
);

const LogoText = styled.div`
  letter-spacing: 0.06em;
  text-transform: uppercase;
  font-size: ${themeGet('fontSizes.4')};
  font-weight: 700;
  font-family: ${themeGet('fonts.heading')};
  -webkit-font-smoothing: antialiased;

  color: ${themeGet('colors.bg')};
`;

const StyledHeaderLogo = styled.button`
  z-index: 30;
  height: 65px;

  background: ${themeGet('colors.main')};
  outline: none;

  cursor: pointer;

  display: flex;
  align-items: center;

  @media ${NAV_MINI_BREAKPOINT} {
    height: 55px;
  }

  @media ${NAV_BREAKPOINT} {
    padding: 0;
  }
`;

const HeaderLogo = (props) => {
  return (
    <StyledHeaderLogo {...props}>
      <LogoIcon />
      <LogoText>
        <span translate="no" className="notranslate">
          UrakkaDiili
        </span>
      </LogoText>
      <LogoBadge />
      <MenuIndicator />
    </StyledHeaderLogo>
  );
};

export default HeaderLogo;
