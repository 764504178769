import React from 'react';
import {Helmet} from 'react-helmet-async';

import {Heading} from 'rebass/styled-components';

const PageTitle = ({setDocumentTitle = false, children, ...rest}) => (
  <>
    {setDocumentTitle && (
      <Helmet>
        <title>{children}</title>
      </Helmet>
    )}
    <Heading as="h1" fontSize={6} {...rest}>
      {children}
    </Heading>
  </>
);

export default PageTitle;
