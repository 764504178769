import {createGlobalStyle} from 'styled-components/macro';

import visueltLightEot from './visuelt-light.eot';
import visueltLightWoff2 from './visuelt-light.woff2';
import visueltLightWoff from './visuelt-light.woff';
import visueltLightTtf from './visuelt-light.ttf';
import visueltRegularEot from './visuelt-regular.eot';
import visueltRegularWoff2 from './visuelt-regular.woff2';
import visueltRegularWoff from './visuelt-regular.woff';
import visueltRegularTtf from './visuelt-regular.ttf';
import visueltMediumEot from './visuelt-medium.eot';
import visueltMediumWoff2 from './visuelt-medium.woff2';
import visueltMediumWoff from './visuelt-medium.woff';
import visueltMediumTtf from './visuelt-medium.ttf';
import visueltBoldEot from './visuelt-bold.eot';
import visueltBoldWoff2 from './visuelt-bold.woff2';
import visueltBoldWoff from './visuelt-bold.woff';
import visueltBoldTtf from './visuelt-bold.ttf';
import markOTBookEot from './mark-ot-book.eot';
import markOTBookWoff2 from './mark-ot-book.woff2';
import markOTBookWoff from './mark-ot-book.woff';
import markOTBookTtf from './mark-ot-book.ttf';
import markOTBoldEot from './mark-ot-bold.eot';
import markOTBoldWoff2 from './mark-ot-bold.woff2';
import markOTBoldWoff from './mark-ot-bold.woff';
import markOTBoldTtf from './mark-ot-bold.ttf';

const fonts = {
  'visuelt-light': {
    formats: ['eot', 'woff2', 'woff', 'ttf'],
    localUrls: {
      eot: visueltLightEot,
      woff2: visueltLightWoff2,
      woff: visueltLightWoff,
      ttf: visueltLightTtf,
    },
  },
  'visuelt-regular': {
    formats: ['eot', 'woff2', 'woff', 'ttf'],
    localUrls: {
      eot: visueltRegularEot,
      woff2: visueltRegularWoff2,
      woff: visueltRegularWoff,
      ttf: visueltRegularTtf,
    },
  },
  'visuelt-medium': {
    formats: ['eot', 'woff2', 'woff', 'ttf'],
    localUrls: {
      eot: visueltMediumEot,
      woff2: visueltMediumWoff2,
      woff: visueltMediumWoff,
      ttf: visueltMediumTtf,
    },
  },
  'visuelt-bold': {
    formats: ['eot', 'woff2', 'woff', 'ttf'],
    localUrls: {
      eot: visueltBoldEot,
      woff2: visueltBoldWoff2,
      woff: visueltBoldWoff,
      ttf: visueltBoldTtf,
    },
  },
  'mark-ot-book': {
    formats: ['eot', 'woff2', 'woff', 'ttf'],
    localUrls: {
      eot: markOTBookEot,
      woff2: markOTBookWoff2,
      woff: markOTBookWoff,
      ttf: markOTBookTtf,
    },
  },
  'mark-ot-bold': {
    formats: ['eot', 'woff2', 'woff', 'ttf'],
    localUrls: {
      eot: markOTBoldEot,
      woff2: markOTBoldWoff2,
      woff: markOTBoldWoff,
      ttf: markOTBoldTtf,
    },
  },
};

const fontUrls = {};

const remoteFontBaseUrl = process.env.REACT_APP_FONT_URL;
for (const [name, {formats, localUrls}] of Object.entries(fonts)) {
  const urls = {};
  for (const format of formats) {
    if (remoteFontBaseUrl) {
      urls[format] = `${remoteFontBaseUrl}${name}.${format}`;
    } else {
      urls[format] = localUrls[format];
    }
  }
  fontUrls[name] = urls;
}

const Fonts = createGlobalStyle`
  @font-face {
    font-family: "Visuelt";
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
    src: url("${fontUrls['visuelt-light'].eot}");
    src: url("${fontUrls['visuelt-light'].eot}?#iefix") format("embedded-opentype"), url("${fontUrls['visuelt-light'].woff2}") format("woff2"), url("${fontUrls['visuelt-light'].woff}") format("woff"), url("${fontUrls['visuelt-light'].ttf}") format("truetype");
  }

  @font-face {
    font-family: "Visuelt";
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
    src: url("${fontUrls['visuelt-regular'].eot}");
    src: url("${fontUrls['visuelt-regular'].eot}?#iefix") format("embedded-opentype"), url("${fontUrls['visuelt-regular'].woff2}") format("woff2"), url("${fontUrls['visuelt-regular'].woff}") format("woff"), url("${fontUrls['visuelt-regular'].ttf}") format("truetype");
  }

  @font-face {
    font-family: "Visuelt";
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
    src: url("${fontUrls['visuelt-medium'].eot}");
    src: url("${fontUrls['visuelt-medium'].eot}?#iefix") format("embedded-opentype"), url("${fontUrls['visuelt-medium'].woff2}") format("woff2"), url("${fontUrls['visuelt-medium'].woff}") format("woff"), url("${fontUrls['visuelt-medium'].ttf}") format("truetype");
  }

  @font-face {
    font-family: "Visuelt";
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
    src: url("${fontUrls['visuelt-bold'].eot}");
    src: url("${fontUrls['visuelt-bold'].eot}?#iefix") format("embedded-opentype"), url("${fontUrls['visuelt-bold'].woff2}") format("woff2"), url("${fontUrls['visuelt-bold'].woff}") format("woff"), url("${fontUrls['visuelt-bold'].ttf}") format("truetype");
  }

  @font-face {
    font-family: "MarkOT";
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
    src: url("${fontUrls['mark-ot-book'].eot}");
    src: url("${fontUrls['mark-ot-book'].eot}?#iefix") format("embedded-opentype"), url("${fontUrls['mark-ot-book'].woff2}") format("woff2"), url("${fontUrls['mark-ot-book'].woff}") format("woff"), url("${fontUrls['mark-ot-book'].ttf}") format("truetype");
  }

  @font-face {
    font-family: "MarkOT";
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
    src: url("${fontUrls['mark-ot-bold'].eot}");
    src: url("${fontUrls['mark-ot-bold'].eot}?#iefix") format("embedded-opentype"), url("${fontUrls['mark-ot-bold'].woff2}") format("woff2"), url("${fontUrls['mark-ot-bold'].woff}") format("woff"), url("${fontUrls['mark-ot-bold'].ttf}") format("truetype");
  }
`;

export default Fonts;
