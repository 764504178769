import {createGlobalStyle} from 'styled-components/macro';
import {themeGet} from '@styled-system/theme-get';

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    line-height: 1.15;
    text-size-adjust: 100%;
    font-size: 62.5%;
    font-family: sans-serif;
  }

  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
   display: block;
  }

  body {
    margin: 0;
    font-family: ${themeGet('fonts.default')};
    font-size: ${themeGet('fontSizes.2')};
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: ${themeGet('colors.text')};
    background-color: ${themeGet('colors.bg')};
  }

  [tabindex="-1"]:focus {
    outline: 0 !important;
  }

  p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  a, a:link, a:visited, a:hover, a:active {
    color: inherit;
  }

  img {
    border-style: none;
  }

  svg {
    overflow: hidden;
    vertical-align: middle;
  }

  table {
    border-collapse: collapse;
  }

  th {
    text-align: inherit;
  }

  label {
    display: block;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  select {
    text-transform: inherit;
  }

  button {
    display: block;
    margin: 0;
    padding: 0;

    appearance: none;
    border: 0;
    border-radius: 0;

    text-align: left;
    line-height: inherit;
    font-family: inherit;
    font-size: inherit;

    color: inherit;
    background-color: transparent;

    user-select: none;

    &::-moz-focus-inner {
      padding: 0;
      border-style: none;
    }
  }

  input, textarea {
    font-family: inherit;
  }

  input[type="radio"],
  input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    -webkit-appearance: listbox;
  }

  textarea {
    margin: 0;
    overflow: auto;
    resize: vertical;
  }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }

  legend {
    display: block;
    width: auto;
    max-width: none;
    padding: 0;
    margin: 0;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    white-space: inherit;
  }
`;

export default GlobalStyle;
