import React, {
  useState,
  useLayoutEffect,
  useRef,
  useMemo,
  useContext,
  createContext,
  useEffect,
} from 'react';
import styled, {css} from 'styled-components/macro';
import {themeGet} from '@styled-system/theme-get';
import {useNavigate, useLocation} from 'react-router-dom';

import useMedia from '../hooks/useMedia';
import useOnClickOutside from '../hooks/useOnClickOutside';
import {urls} from '../utils/localization';
import withPerspective from '../utils/withPerspective';
import {NAV_BREAKPOINT, MenuStateProvider} from './headerUtils';
import HeaderLogo from './HeaderLogo';
import HeaderNav from './HeaderNav';

const HeaderStickinessContext = createContext(false);
const HeaderStickinessSetterContext = createContext(() => {});

const HeaderStickinessProvider = ({children}) => {
  const [stickyCount, setStickyCount] = useState(0);
  return (
    <HeaderStickinessSetterContext.Provider value={setStickyCount}>
      <HeaderStickinessContext.Provider value={stickyCount > 0}>
        {children}
      </HeaderStickinessContext.Provider>
    </HeaderStickinessSetterContext.Provider>
  );
};

const MakeHeaderSticky = () => {
  const setStickiness = useContext(HeaderStickinessSetterContext);

  useLayoutEffect(() => {
    setStickiness((i) => i + 1);
    return () => {
      setStickiness((i) => i - 1);
    };
  }, [setStickiness]);

  return null;
};

const HeaderContentContainer = styled.div`
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  max-width: 1140px;

  @media ${NAV_BREAKPOINT} {
    flex-direction: row;
  }
`;

const StyledHeader = styled.div`
  z-index: 5;

  ${({isSticky}) =>
    isSticky &&
    css`
      position: sticky;
      top: 0;
    `}

  color: ${themeGet('colors.textLight')};
  background: ${themeGet('colors.text')};

  user-select: none;
`;

const Header = withPerspective(({perspective}) => {
  const isSticky = useContext(HeaderStickinessContext);

  const navIsHorizontal = useMedia(NAV_BREAKPOINT);
  const [menuIsOpenState, setMenuIsOpen] = useState(false);
  const navigate = useNavigate();

  const menuCanOpen = perspective !== null && !navIsHorizontal;
  const menuIsOpen = menuCanOpen && menuIsOpenState;
  const menuState = useMemo(
    () => ({
      menuCanOpen,
      menuIsOpen,
    }),
    [menuCanOpen, menuIsOpen]
  );

  const handleLogoClick = () => {
    if (menuCanOpen) {
      setMenuIsOpen(!menuIsOpen);
    } else if (perspective !== null || process.env.NODE_ENV === 'development') {
      navigate(urls.home, {replace: true});
    } else {
      window.location.assign(process.env.REACT_APP_MARKETING_SITE_URL);
    }
  };

  const headerRef = useRef(null);
  useOnClickOutside(headerRef, () => setMenuIsOpen(false), menuIsOpen);

  const location = useLocation();

  useEffect(() => {
    menuCanOpen && location && setMenuIsOpen(false);
  }, [location, menuCanOpen]);

  return (
    <MenuStateProvider value={menuState}>
      <StyledHeader ref={headerRef} isSticky={isSticky}>
        <HeaderContentContainer>
          <HeaderLogo onClick={handleLogoClick} />
          <HeaderNav />
        </HeaderContentContainer>
      </StyledHeader>
    </MenuStateProvider>
  );
});

export default Header;
export {HeaderStickinessProvider, MakeHeaderSticky};
