import React, {useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import styled from 'styled-components/macro';
import {themeGet} from '@styled-system/theme-get';
import {Box, Heading, Text} from 'rebass/styled-components';
import {IoIosArrowDown, IoIosArrowUp} from 'react-icons/io';

import {urls} from '../utils/localization';
import {useCurrentUser} from '../utils/withPerspective';

import BoxListItem from '../primitives/BoxListItem';
import ContentContainer from '../primitives/ContentContainer';
import Link from '../primitives/Link';

const StyledUserAttentionRequiredList = styled.div`
  padding: 0 ${themeGet('space.8')};
`;

const StyledMenuIndicator = styled.div`
  font-size: ${themeGet('fontSizes.4')};
  font-weight: 700;

  margin-left: calc(${themeGet('space.6')} - 2px);
`;

const MenuIndicator = ({open}) => {
  return (
    <StyledMenuIndicator>
      {!open && <IoIosArrowDown />}
      {open && <IoIosArrowUp />}
    </StyledMenuIndicator>
  );
};

const StyledHeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: ${themeGet('space.8')} ${themeGet('space.8')};

  background: ${themeGet('colors.bgActive')};

  @media (min-width: 500px) {
    line-height: 1em;
  }
`;

const StyledContentContainer = styled(ContentContainer)`
  margin-top: ${themeGet('space.8')};

  border: 3px dashed
    ${({borderColor = 'primary'}) => themeGet(`colors.${borderColor}`)};
`;

const UrgentNotification = styled.div`
  margin: ${themeGet('space.8')} 0;
  padding: 0 ${themeGet('space.5')};
  text-align: center;
`;

const UserAttentionRequired = () => {
  const currentUser = useCurrentUser();

  const navigate = useNavigate();
  const location = useLocation();

  const [showUserAttentionRequiredList, setShowUserAttentionRequiredList] =
    useState(false);

  if (!currentUser || currentUser.type !== 'contractor') return null;

  const {userAttentionRequired} = currentUser;

  const pendingSupportMessages = userAttentionRequired.filter(
    (item) => item.type === 'pendingSupportMessage'
  );
  const deadlineExceededSupportMessages = userAttentionRequired.filter(
    (item) => item.type === 'deadlineExceededSupportMessage'
  );
  const workStartDateMissing = userAttentionRequired.filter(
    (item) => item.type === 'workStartDateMissing'
  );
  const finalPriceMissing = userAttentionRequired.filter(
    (item) => item.type === 'finalPriceMissing'
  );
  const serviceFeeBillingInformationIsMissing = userAttentionRequired.filter(
    (item) => item.type === 'serviceFeeBillingInformationIsMissing'
  );

  if (
    deadlineExceededSupportMessages.length > 0 &&
    location.pathname !== urls.support
  ) {
    navigate(urls.support, {replace: true});
  }

  const containerType =
    location.pathname === urls.home ||
    location.pathname === urls.offerRequests ||
    location.pathname === urls.support
      ? 'wide'
      : 'regular';

  return userAttentionRequired.length > 0 ? (
    <StyledContentContainer
      type={containerType}
      borderColor={
        deadlineExceededSupportMessages.length > 0 ? 'warning' : 'primary'
      }
    >
      <StyledHeadingWrapper
        onClick={() =>
          setShowUserAttentionRequiredList(
            !showUserAttentionRequiredList && true
          )
        }
      >
        <Heading as="h2" fontSize={4}>
          {userAttentionRequired.length} huomiota{' '}
          {userAttentionRequired.length === 1
            ? 'vaativa asia'
            : 'vaativaa asiaa'}
          {deadlineExceededSupportMessages.length > 0 &&
            `, joista ${deadlineExceededSupportMessages.length} ${
              deadlineExceededSupportMessages.length === 1
                ? 'kiireellinen'
                : 'kiireellistä'
            }`}
        </Heading>
        <MenuIndicator open={showUserAttentionRequiredList} />
      </StyledHeadingWrapper>
      {deadlineExceededSupportMessages.length > 0 && (
        <UrgentNotification>
          Vastaa aikarajan ylittäneeseen tukiviestiin jatkaaksesi UrakkaDiilin
          käyttöä
        </UrgentNotification>
      )}
      {showUserAttentionRequiredList && (
        <StyledUserAttentionRequiredList>
          {pendingSupportMessages.length > 0 && (
            <>
              <Heading as="h3" fontSize={3} my={[7, 8]}>
                Tukiviesti UrakkaDiililtä odottaa vastaustasi
              </Heading>
              <Text mb={[8, 9]}>
                <Link
                  to={`${urls.support}`}
                  onClick={() => setShowUserAttentionRequiredList(false)}
                >
                  Lue viesti
                </Link>
              </Text>
            </>
          )}
          {serviceFeeBillingInformationIsMissing.length > 0 && (
            <>
              <Heading as="h3" fontSize={3} my={[7, 8]}>
                Täytä laskutustiedot
              </Heading>
              <Text mb={[8, 9]}>
                Ole hyvä, täytä yrityksen osoite- ja laskutustiedot{' '}
                <Link
                  to={urls.profile}
                  onClick={() => setShowUserAttentionRequiredList(false)}
                >
                  täällä
                </Link>
                .
              </Text>
            </>
          )}
          {workStartDateMissing.length > 0 && (
            <>
              <Heading as="h3" fontSize={3} my={[7, 8]}>
                Aloituspäivämäärä puuttuu
              </Heading>
              <Text mb={[7, 8]}>
                Lisää kohteelle aloituspäivämäärä tai merkitse arvio milloin
                aikataulusta tullaan sopimaan
              </Text>
              <Box mb={[7, 8]}>
                {workStartDateMissing.map(
                  (
                    {
                      offerRequest: {
                        id: offerRequestId,
                        location: {displayName},
                      },
                    },
                    i
                  ) => (
                    <BoxListItem
                      key={i}
                      as={Link}
                      to={`${urls.offerRequests}/${offerRequestId}`}
                      onClick={() => setShowUserAttentionRequiredList(false)}
                    >
                      {displayName}
                    </BoxListItem>
                  )
                )}
              </Box>
            </>
          )}
          {finalPriceMissing.length > 0 && (
            <>
              <Heading as="h3" fontSize={3} my={[7, 8]}>
                Asiakkaalta laskutettu hinta puuttuu
              </Heading>
              <Text mb={[7, 8]}>
                Merkitse asiakkaalta laskutettu hinta tai siirrä kohteen
                arvioitua valmistumisaikaa
              </Text>
              <Box mb={[7, 8]}>
                {finalPriceMissing.map(
                  (
                    {
                      offerRequest: {
                        id: offerRequestId,
                        location: {displayName},
                      },
                    },
                    i
                  ) => (
                    <BoxListItem
                      key={i}
                      as={Link}
                      to={`${urls.offerRequests}/${offerRequestId}`}
                      onClick={() => setShowUserAttentionRequiredList(false)}
                    >
                      {displayName}
                    </BoxListItem>
                  )
                )}
              </Box>
            </>
          )}
        </StyledUserAttentionRequiredList>
      )}
    </StyledContentContainer>
  ) : null;
};

export default UserAttentionRequired;
