import {useRoutes} from 'react-router-dom';

import {urls} from './utils/localization';
import withPerspective from './utils/withPerspective';
import {lazyLoadRoute} from './utils/routeUtils';

import {guestRoutes} from './GuestRoutes';
import {adminRoutes} from './AdminRoutes';
import {salesAgentRoutes} from './SalesAgentRoutes';
import {clientRoutes} from './ClientRoutes';
import {contractorRoutes} from './ContractorRoutes';

const defaultOptions = (routes) =>
  routes.map(({end = true, caseSensitive = true, ...rest}) => {
    return {end, caseSensitive, ...rest};
  });

const Routes = withPerspective(({perspective}) => {
  let routes = [{path: urls.login, element: lazyLoadRoute('Login')}];

  if (perspective === null)
    routes = [...routes, ...defaultOptions(guestRoutes)];
  if (perspective === 'admin')
    routes = [...routes, ...defaultOptions(adminRoutes)];
  if (perspective === 'salesAgent')
    routes = [...routes, ...defaultOptions(salesAgentRoutes)];
  if (perspective === 'client')
    routes = [...routes, ...defaultOptions(clientRoutes)];
  if (perspective === 'contractor')
    routes = [...routes, ...defaultOptions(contractorRoutes)];

  return useRoutes(routes);
});

export default Routes;
