const refreshOnImportFailure = async (importPromise) => {
  try {
    return await importPromise;
  } catch (error) {
    window.location.reload();
    throw error;
  }
};

export default refreshOnImportFailure;
