import styled from 'styled-components/macro';
import {themeGet} from '@styled-system/theme-get';
import {Box} from 'rebass/styled-components';

const defaultMarginTop = [8, 9];
const defaultMarginBottom = [7, 8];

const HeadingWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${({mt = defaultMarginTop}) => themeGet(`space.${mt[0]}`)};
  margin-bottom: ${({mb = defaultMarginBottom}) => themeGet(`space.${mb[0]}`)};

  @media (min-width: 500px) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${({mt = defaultMarginTop}) => themeGet(`space.${mt[1]}`)};
    margin-bottom: ${({mb = defaultMarginBottom}) =>
      themeGet(`space.${mb[1]}`)};
  }
`;

export default HeadingWrapper;
