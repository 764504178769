const urls = {
  home: '/',
  offerRequests: '/tarjouspyynnot',
  offers: '/tarjoukset',
  orders: '/tilaukset',
  offerTemplates: '/tarjouspohjat',
  offerRequestFilters: '/hakuvahdit',
  clients: '/asiakkaat',
  contractors: '/urakoitsijat',
  messages: '/viestit',
  messageTemplates: '/viestipohjat',
  profile: '/profiili',
  login: '/kirjaudu',
  new: '/uusi',
  edit: '/muokkaa',
  thankYou: '/kiitos',
  review: '/arvioi',
  reviews: '/arviot',
  tasks: '/tehtavat',
  support: '/tuki',
  jobTypes: '/tyotyypit',
  serviceFees: '/palvelumaksut',
  serviceFeeInvoices: '/palvelumaksulaskut',
  serviceFeeInvoiceTransactions: '/palvelumaksulaskusuoritukset',
  joinAsPro: '/liity',
};

const offerRequestStatuses = {
  pendingReview: 'Odottaa hyväksyntää',
  pendingRevision: 'Odottaa korjausta',
  acceptingOffers: 'Odottaa tarjouksia',
  closedToOffers: 'Tarjouskilpailu päättynyt',
  rejected: 'Hylätty',
};

const offerRequestFollowupTypes = {
  'client-follow-up-1': 'Follow up: tiedustelu, milloin tekee päätöksiä',
  'client-follow-up-2': 'Follow up: muistutus',
  'client-follow-up-3': 'Follow up: onko vielä ajankohtainen?',
  'client-follow-up-apology-1': 'Pahoittelut, ei tarjouksia',
  'client-follow-up-apology-2': 'Pahoittelut, ei useampia tarjouksia',
  'client-follow-up-step1': 'client-follow-up-step1',
  'client-follow-up-step2-0offers': 'client-follow-up-step2-0offers',
  'client-follow-up-step2-1offer': 'client-follow-up-step2-1offer',
  'client-follow-up-step2-over1offers': 'client-follow-up-step2-over1offers',
  'client-follow-up-step3-over0offers': 'client-follow-up-step3-over0offers',
  'client-follow-up-step4-over0offers': 'client-follow-up-step4-over0offers',
  'client-follow-up-step5-over0offers': 'client-follow-up-step5-over0offers',
  'client-follow-up-the-last-one': 'client-follow-up-the-last-one',
  'client-follow-up-180': 'client-follow-up-180',
  'client-follow-up-365': 'client-follow-up-365',
  'client-follow-up-tried-to-reach-by-phone': 'Yritetty tavoitella puhelimitse',
  'client-follow-up-found-on-urakkadiili-but-who': 'Minkä yrityksen valitsit',
  'client-follow-up-found-on-urakkadiili-tried-to-reach-by-phone':
    'Löysi UrakkaDiilistä, yritetty tavoitella puhelimitse',
  'client-follow-up-found-on-urakkadiili-contractor-says-no':
    'Urakoitsija kertoi ettei ole saanut kohdetta',
};

const offerRequestFilters = {
  contractorAwaitingOffer: 'Tarjouspyynnöt',
  contractorOfferPendingAcceptance: 'Tarjoukset',
  contractorOfferAccepted: 'Sopimusluonnokset',
  contractorOfferTermsAccepted: 'Sopimukset',
  contractorOfferFailed: 'Epäonnistuneet',
  open: 'Avoimet',
  contacted: 'Kontaktoidut',
  accepted: 'Tilaukset',
  completed: 'Valmiit',
  favorites: 'Suosikit',
  quiet: 'Hiljaiset',
};

const offerRequestBuildingTypes = {
  kerrostalo: 'Kerrostalo',
  omakotitalo: 'Omakotitalo',
  rivitalo: 'Rivitalo',
  paritalo: 'Paritalo',
  erillistalo: 'Erillistalo',
  vapaaAjanAsunto: 'Vapaa-ajan asunto',
  toimitila: 'Toimitila',
  other: 'Muu',
};

const offerRequestCompletionWindows = {
  asap: 'niin pian kuin mahdollista',
  oneWeek: '1\u{a0}viikossa',
  twoWeeks: '2\u{a0}viikossa',
  oneMonth: 'kuukaudessa',
};

const offerRequestSchedules = {
  flexible: 'Aikataulu on joustava',
  asap: 'Mahdollisimman pian',
  withinTwoMonths: 'Seuraavan kahden kuukauden sisällä',
  afterTwoMonths: 'Yli kahden kuukauden päästä',
};

const offerRequestCommitmentLevels = {
  readyToHire: 'Olen päättänyt tehdä remontin ja etsin tekijää',
  considering: 'Harkitsen remonttia',
};

const offerRequestUserClosureReasons = {
  foundOnUrakkaDiili: 'Löysin tekijän UrakkaDiilistä',
  foundElsewhere: 'Löysin tekijän muualta',
  diy: 'Teen itse työn',
  notThisTime: 'En teetä työtä tällä kertaa',
  postponed: 'Työ siirtyi tulevaisuuteen',
  cancelled: 'Tarve työlle peruuntui',
  other: 'Muu syy, mikä?',
  otherContractor: 'Muu yritys, mikä?',
};

const offerDurationEstimates = {
  oneDay: '1\u{a0}päivä',
  twoThreeDays: '2–3\u{a0}päivää',
  fourFiveDays: '4–5\u{a0}päivää',
  week: 'noin viikko',
  twoWeeks: 'noin 2\u{a0}viikkoa',
  threeWeeks: 'noin 3\u{a0}viikkoa',
  oneMonth: 'noin kuukausi',
  overMonth: 'yli kuukausi',
};

const orderWorkDates = {
  workStartDateReminderIs: 'Aloituspäivämäärä sovitaan',
  workStartDateMissing: 'Aloituspäivämäärä puuttuu',
  workStartDateIs: 'Työ alkaa',
  workCompletionDateReminderIs: 'Valmistumispäivämäärä sovitaan',
  workCompletionDateMissing: 'Valmistumispäivämäärä puuttuu',
  workCompletionDateIs: 'Valmistuu',
  finalPriceMissing: 'Laskutettu hinta puuttuu',
};

const priceUnits = {
  lump: {
    label: 'urakka',
    descriptiveLabel: 'Kiinteä hinta',
    priceLabel: '€',
    priceDescriptiveLabel: 'Hinta',
    priceElativeLabel: 'hinnasta',
    estimateLabel: null,
  },
  hour: {
    label: 'h',
    descriptiveLabel: 'Tuntihinta',
    priceLabel: '€/h',
    priceDescriptiveLabel: 'Tuntihinta',
    priceElativeLabel: 'tuntihinnasta',
    estimateLabel: 'Arvio tunneista',
  },
  piece: {
    label: 'kpl',
    descriptiveLabel: 'Kappalehinta',
    priceLabel: '€/kpl',
    priceDescriptiveLabel: 'Kappalehinta',
    priceElativeLabel: 'kappalehinnasta',
    estimateLabel: 'Arvio kappalemäärästä',
  },
  squareMeter: {
    label: 'm²',
    descriptiveLabel: 'Neliöhinta',
    priceLabel: '€/m²',
    priceDescriptiveLabel: 'Neliöhinta',
    priceElativeLabel: 'neliöhinnasta',
    estimateLabel: 'Arvio neliöistä',
  },
};

const inclusionChoices = {
  included: 'Sisältyy',
  extra: 'Onnistuu lisähintaan',
  no: 'Ei onnistu',
};

const offerRequestContactClientTypes = {
  privatePerson: 'Yksityishenkilö(t)',
  condominium: 'Asunto-osakeyhtiö',
  business: 'Yritys/yhteisö',
};

const orderStatuses = {
  active: 'Aktiivinen',
  cancelled: 'Peruttu',
  invoiced: 'Valmis',
};

const serviceFeeStatuses = {
  pending: 'Odottaa laskutusta',
  cancelled: 'Peruttu',
  invoiced: 'Laskutettu',
};

const serviceFeeInvoiceTypes = {
  sales: 'Myyntilasku',
  credit: 'Hyvityslasku',
};

const serviceFeeInvoiceEvents = {
  creditInvoice: 'Hyvityslasku',
  transaction: 'Maksu',
};

const serviceFeeInvoiceStatuses = {
  active: 'Aktiivinen',
  credit: 'Hyvitetty',
};

const serviceFeeInvoiceTransactionStatuses = {
  pending: 'Odottaa',
  splitted: 'Jaettu',
  targeted: 'Kohdistettu',
  rejected: 'Hylätty',
};

const contractorStats = {
  offerRequests: {
    heading: 'Tarjouspyyntöjä',
    count: 'Kohdistettu',
    readCount: 'Luettu',
    favouritesCount: 'Suosikiksi merkitty',
    showedInterest: 'Osoittanut kiinnotusta',
  },
  messages: {
    heading: 'Viestit',
    count: 'Lähetetty ja vastaanotettu',
  },
  phoneCalls: {
    heading: 'Puhelut',
    count: 'Soitettu ja vastaanotettu',
  },
  offers: {
    heading: 'Tarjoukset',
    count: 'Tarjouksia',
    acceptedCount: 'Hyväksytty',
  },
  orders: {
    heading: 'Tilaukset',
    count: 'Tilauksia',
    cancelledCount: 'Peruutettu',
    finalPriceIsNullCount: 'Laskutettu hinta ilmoittamatta',
  },
};

const serviceFeeBillingMethods = {
  eInvoice: 'verkkolasku',
  email: 'sähköpostilla',
  snailMail: 'paperilasku',
};

const adminTaskTypes = {
  clientFollowUpCall: 'Soita asiakkaalle',
  reviewYtjInformation: 'Tarkista YTJ-tiedot',
  didYouGetThisOfferRequest: 'Saitko tämän kohteen',
  manual: 'Manuaalinen',
  reviewFinalPrice: 'Tarkista laskutettu hinta',
  joinAsPro: 'Uusi urakoitsija',
  foundOnUrakkaDiili: 'Löysi UrakkaDiilistä',
  emailMessageEvent: 'Sähköpostitapahtuma',
  callToOfferRequest: 'Soita tarjouspyynnöstä',
  contractorWonOfferRequest: 'Urakoitsija: voitto',
  contractorLostToCompetitor: 'Urakoitsija: hävitty kilpailijalle',
  contractorClientDoesNotRespond: 'Urakoitsija: ei vastaa',
  contractorClientDoesNotNeed: 'Urakoitsija: asiakas ei tarvitse',
  checkNewContractor: 'Tarkista uusi urakoitsija',
};

const adminTaskStatuses = {
  open: 'Avoin',
  closed: 'Suljettu',
  snoozed: 'Torkutettu',
};

const supportMessageStatuses = {
  closed: 'Suljettu',
  pendingAdmin: 'Odottaa yhteyshenkilön käsittelyä',
  pendingContractor: 'Odottaa urakoitsijan vastausta',
};

const supportMessageTemplateTypes = {
  didYouGetThisOfferRequest: 'Saitko tämän kohteen?',
  checkThisAgain: 'Asiakas etsii tekijää',
  contractorWorkStartDateRequest: 'Onko työn ajankohta sovittu?',
  remindToSubmitFinalPrice: 'Muista lisätä asiakkaalta laskutettu hinta',
};

const supportMessageRecipientRespondOptions = {
  yesWeGot: 'Kyllä',
  notYet: 'Ei vielä, neuvottelut kesken',
  noWeDidNotGet: 'Ei',
  weWillContact: 'Ok, kontaktoimme asiakkaan',
  weAreBusy: 'Kalenteri on täynnä',
  weAreNotInterested: 'Ei ole mielenkiintoinen',
  other: 'Muu',
};

const supportMessageRecipientRespondQuestions = {
  yesWeGot: `Milloin työ alkaa tai on alkanut ja mikä on sovittu tai toteutunut
  hinta?`,
  notYet: `Mikä on paras arvaus milloin asiakas tekee päätöksiä?`,
  other: `Kerro lisää viestillä.`,
};

const contractorOfferRequestStatuses = {
  active: 'Aktiivinen',
  clientDoesNotRespond: 'Asiakas ei vastaa',
  won: 'Voitettu',
  lostToCompetitor: 'Hävitty kilpailijalle',
  clientDoesNotNeed: 'Asiakas jättää tekemättä',
};

export {
  urls,
  offerRequestStatuses,
  offerRequestFollowupTypes,
  offerRequestFilters,
  offerRequestBuildingTypes,
  offerRequestCompletionWindows,
  offerRequestSchedules,
  offerRequestCommitmentLevels,
  offerRequestUserClosureReasons,
  offerDurationEstimates,
  orderWorkDates,
  priceUnits,
  inclusionChoices,
  offerRequestContactClientTypes,
  orderStatuses,
  serviceFeeStatuses,
  serviceFeeInvoiceTypes,
  serviceFeeInvoiceEvents,
  serviceFeeInvoiceStatuses,
  serviceFeeInvoiceTransactionStatuses,
  contractorStats,
  serviceFeeBillingMethods,
  adminTaskTypes,
  adminTaskStatuses,
  supportMessageStatuses,
  supportMessageTemplateTypes,
  supportMessageRecipientRespondOptions,
  supportMessageRecipientRespondQuestions,
  contractorOfferRequestStatuses,
};
