import React, {useEffect} from 'react';
import {useQuery} from '@apollo/client';
import {
  captureException,
  captureMessage,
  configureScope as configureSentryScope,
} from '@sentry/browser';
import {gql} from '@apollo/client';

import useScript from '../hooks/useScript';

const logError = (error) => {
  console.error(error);
  captureException(error);
};

const logMessage = (message) => {
  console.error(message);
  captureMessage(message);
};

const logRocketScriptUrl = 'https://cdn.lr-ingest.io/LogRocket.min.js';

const logRocketIdentityQuery = gql`
  query LogRockerIdentity {
    viewer {
      id
      type
      email
    }
  }
`;

const LogRocketIdentifier = () => {
  const result = useQuery(logRocketIdentityQuery);

  const data = result?.data || null;
  const error = result?.error || null;
  const viewer = data?.viewer || null;
  const userId = viewer?.id || null;
  const userType = viewer?.type || null;
  const userEmail = viewer?.email || null;

  useEffect(() => {
    if (error !== null) {
      logError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error === null]);

  useEffect(() => {
    if (data === null) return;
    if (!window.LogRocket) {
      logMessage('window.LogRocket is falsy inside LogRocketIdentifier');
      return;
    }

    try {
      window.LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID);
      window.LogRocket.getSessionURL((sessionURL) => {
        configureSentryScope((scope) => {
          scope.setExtra('sessionURL', sessionURL);
        });
      });
      if (userId !== null) {
        const extra = {};
        if (userType !== null) extra.type = userType;
        if (userEmail !== null) extra.email = userEmail;
        window.LogRocket.identify(userId, extra);
      }
    } catch (error) {
      logError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data === null, userId]);

  return null;
};

const LogRocket = () => {
  if (!process.env.REACT_APP_LOGROCKET_APP_ID) return null;

  // Whether this component is bailed out of early is a build-time constant
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isLoaded = useScript(logRocketScriptUrl);

  if (!isLoaded) return null;
  if (!window.LogRocket) {
    logMessage(
      'LogRocket script isLoaded === true but window.LogRocket is falsy'
    );
    return null;
  }
  return <LogRocketIdentifier />;
};

export default LogRocket;
