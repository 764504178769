import React, {useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import styled, {css} from 'styled-components/macro';
import {themeGet} from '@styled-system/theme-get';
import {IoIosContact, IoIosMenu} from 'react-icons/io';
import {gql, useQuery} from '@apollo/client';

import {Loading} from '../utils/LoadingBoundary';
import {DataError} from '../utils/DataErrorBoundary';
import withPerspective from '../utils/withPerspective';
import {formatNumber} from '../utils/formatters';
import {urls} from '../utils/localization';
import Button from '../primitives/Button';
import NavLink from '../primitives/NavLink';
import {NAV_BREAKPOINT, NAV_MINI_BREAKPOINT, useMenuState} from './headerUtils';
import HeaderBadge from './HeaderBadge';

const HeaderNavLink = styled(NavLink)`
  height: 65px;
  padding: 0 ${themeGet('space.7')};
  padding-left: calc(env(safe-area-inset-left) + ${themeGet('space.7')});
  padding-right: calc(env(safe-area-inset-right) + ${themeGet('space.7')});

  font-size: ${themeGet('fontSizes.3')};
  font-weight: 700;

  text-decoration: none;

  background: ${themeGet('colors.main')};

  transition: background-color ${themeGet('transitions.fast')};

  /* Vertically center link text */
  display: flex;
  align-items: center;

  &,
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: ${themeGet('colors.bg')};
  }

  &.active,
  &:hover {
    background: ${themeGet('colors.primary')};
  }

  @media ${NAV_MINI_BREAKPOINT} {
    height: 55px;
  }

  @media ${NAV_BREAKPOINT} {
    display: ${(props) => (props?.hidedesktop ? 'none' : 'flex')};
  }
`;

const LinkBadge = styled(HeaderBadge)`
  margin-left: ${themeGet('space.5')};

  @media ${NAV_BREAKPOINT} {
    position: relative;
    top: -5px;
  }

  ${HeaderNavLink}.active &, ${HeaderNavLink}:hover & {
    background: ${themeGet('colors.secondary')};
  }
`;

const StyledProfileLink = styled(HeaderNavLink)`
  > svg {
    margin-right: ${themeGet('space.5')};

    font-size: 2rem;
  }

  @media ${NAV_BREAKPOINT} {
    flex-direction: row-reverse;

    > svg {
      margin-left: ${themeGet('space.5')};
      margin-right: 0;
    }
  }
`;

const ExternalLink = styled.a`
  height: 65px;
  padding: 0 ${themeGet('space.7')};
  padding-left: calc(env(safe-area-inset-left) + ${themeGet('space.7')});
  padding-right: calc(env(safe-area-inset-right) + ${themeGet('space.7')});

  font-size: ${themeGet('fontSizes.3')};
  font-weight: 700;

  text-decoration: none;

  background: ${themeGet('colors.main')};

  transition: background-color ${themeGet('transitions.fast')};

  /* Vertically center link text */
  display: flex;
  align-items: center;

  &,
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: ${themeGet('colors.bg')};
  }

  &.active,
  &:hover {
    background: ${themeGet('colors.primary')};
  }

  @media ${NAV_MINI_BREAKPOINT} {
    height: 55px;
  }
`;

const NavLinksGuest = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname.startsWith(urls.offerRequests)) return null;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;

        height: 65px;
        @media ${NAV_MINI_BREAKPOINT} {
          height: 55px;
        }
        padding-left: calc(env(safe-area-inset-left) + ${themeGet('space.7')});
        padding-right: calc(
          env(safe-area-inset-right) + ${themeGet('space.7')}
        );

        background: ${themeGet('colors.main')};
      `}
    >
      <Button
        // Not sure why <Link as={Button}> doesn't work
        onClick={() =>
          void navigate(`${urls.offerRequests}${urls.new}`, {replace: true})
        }
        css={css`
          color: #40311f;
        `}
      >
        Tee uusi tarjouspyyntö
      </Button>
    </div>
  );
};

const ProfileLink = ({children}) => (
  <StyledProfileLink to={urls.profile}>
    <IoIosContact />
    {children}
  </StyledProfileLink>
);

const NavLinksClientQuery = gql`
  query NavLinksClient {
    viewer {
      id
      unreadConversationCount
    }
  }
`;

const NavLinksClient = () => {
  const {loading, error, data} = useQuery(NavLinksClientQuery);

  if (loading) return <Loading />;
  if (error) return <DataError error={error} />;
  const {
    viewer: {unreadConversationCount},
  } = data;

  return (
    <>
      <HeaderNavLink to={urls.offerRequests}>Tarjouspyynnöt</HeaderNavLink>

      <HeaderNavLink to={urls.messages}>
        Viestit
        {!!unreadConversationCount && (
          <LinkBadge colors="primary">
            {formatNumber(unreadConversationCount)}
          </LinkBadge>
        )}
      </HeaderNavLink>

      <ExternalLink
        href="https://urakkadiili.fi/remonttilaina?utm_source=app"
        target="_blank"
        rel="noreferrer"
      >
        Rahoitus
      </ExternalLink>

      <ProfileLink>Profiili</ProfileLink>
    </>
  );
};

const NavLinksContractorQuery = gql`
  query NavLinksContractor {
    viewer {
      id
      unreadConversationCount
      pendingSupportMessagesCount
    }
  }
`;

const HideOnDesktop = styled.span`
  display: inline;

  @media ${NAV_BREAKPOINT} {
    display: none;
  }
`;

const NavLinksContractor = () => {
  const {loading, error, data} = useQuery(NavLinksContractorQuery);

  if (loading) return <Loading />;
  if (error) return <DataError error={error} />;
  const {
    viewer: {unreadConversationCount, pendingSupportMessagesCount},
  } = data;

  return (
    <>
      <HeaderNavLink to={urls.offerRequests}>Tarjouspyynnöt</HeaderNavLink>
      {/*<HeaderNavLink to={urls.orders}>Tilaukset</HeaderNavLink>*/}
      <HeaderNavLink to={urls.offerTemplates}>Tarjouspohjat</HeaderNavLink>
      <HeaderNavLink to={urls.messages}>
        Viestit
        {!!unreadConversationCount && (
          <LinkBadge>
            {formatNumber(unreadConversationCount)}
            <HideOnDesktop> lukematonta viestiä</HideOnDesktop>
          </LinkBadge>
        )}
      </HeaderNavLink>
      <HeaderNavLink to={urls.support}>
        Tuki
        {!!pendingSupportMessagesCount && (
          <LinkBadge>{formatNumber(pendingSupportMessagesCount)}</LinkBadge>
        )}
      </HeaderNavLink>
      <ProfileLink>Profiili</ProfileLink>
    </>
  );
};

const SubNavOpen = styled.div`
  display: none;

  @media ${NAV_BREAKPOINT} {
    padding: 0 ${themeGet('space.7')};

    background: ${themeGet('colors.main')};

    transition: background-color ${themeGet('transitions.fast')};

    /* Vertically center link text */
    display: flex;
    align-items: center;

    &,
    &:link,
    &:visited,
    &:hover,
    &:active {
      color: ${themeGet('colors.bg')};
    }

    &.active,
    &:hover {
      background: ${themeGet('colors.primary')};
    }

    height: 65px;
  }
`;

const SubNavContainer = styled.div``;

const SubNavLinks = styled.div`
  @media ${NAV_BREAKPOINT} {
   {
      ${({subMenuIsOpen}) =>
        subMenuIsOpen
          ? css`
              position: absolute;
              right: 0;
              top: 65px;
              display: block;
              z-index: 50;
            `
          : css`
              display: none;
            `}

  }
`;

const NavLinksAdminQuery = gql`
  query NavLinksAdmin($filters: [OfferRequestFilters]) {
    viewer {
      id
      openTasksCount
      pendingSupportMessagesCount
    }
    offerRequests(filters: $filters) {
      count
    }
  }
`;

const NavLinksAdmin = () => {
  const [subMenuIsOpen, setSubMenuIsOpen] = useState(false);

  const {loading, error, data} = useQuery(NavLinksAdminQuery, {
    variables: {filters: [{key: 'status', value: 'pendingReview'}]},
  });

  if (loading) return <Loading />;
  if (error) return <DataError error={error} />;

  const {
    viewer: {openTasksCount, pendingSupportMessagesCount},
    offerRequests,
  } = data;

  return (
    <>
      <HeaderNavLink to={urls.home} hidedesktop="true">
        Koti
      </HeaderNavLink>
      <HeaderNavLink to={urls.support}>
        Tuki
        {!!pendingSupportMessagesCount && (
          <LinkBadge colors="primary">
            {formatNumber(pendingSupportMessagesCount)}
          </LinkBadge>
        )}
      </HeaderNavLink>
      <HeaderNavLink to={urls.tasks}>
        Tehtävät
        {!!openTasksCount && (
          <LinkBadge colors="primary">{formatNumber(openTasksCount)}</LinkBadge>
        )}
      </HeaderNavLink>
      <HeaderNavLink to={urls.offerRequests}>
        Tarjouspyynnöt
        {offerRequests.count > 0 && (
          <LinkBadge colors="primary">{offerRequests.count}</LinkBadge>
        )}
      </HeaderNavLink>
      <HeaderNavLink to={urls.contractors}>Urakoitsijat</HeaderNavLink>

      <SubNavContainer
        onMouseOver={() => setSubMenuIsOpen(true)}
        onMouseLeave={() => setSubMenuIsOpen(false)}
      >
        <SubNavOpen>
          <IoIosMenu
            css={css`
              font-size: 2rem;
            `}
          />
        </SubNavOpen>
        <SubNavLinks subMenuIsOpen={subMenuIsOpen}>
          <HeaderNavLink to={urls.orders}>Tilaukset</HeaderNavLink>
          <HeaderNavLink to={urls.serviceFeeInvoices}>
            Palvelumaksulaskut
          </HeaderNavLink>
          <HeaderNavLink to={urls.reviews}>Arvostelut</HeaderNavLink>
          <HeaderNavLink to={urls.jobTypes}>Työtyypit</HeaderNavLink>
          <HeaderNavLink to={urls.profile}>Profiili</HeaderNavLink>
        </SubNavLinks>
      </SubNavContainer>
    </>
  );
};

const NavLinksSalesAgentQuery = gql`
  query NavLinksAdmin($filters: [OfferRequestFilters]) {
    viewer {
      id
      openTasksCount
    }
    offerRequests(filters: $filters) {
      count
    }
  }
`;

const NavLinksSalesAgent = () => {
  const {loading, error, data} = useQuery(NavLinksSalesAgentQuery, {
    variables: {filters: [{key: 'status', value: 'pendingReview'}]},
  });

  if (loading) return <Loading />;
  if (error) return <DataError error={error} />;

  const {
    viewer: {openTasksCount},
    offerRequests,
  } = data;

  return (
    <>
      <HeaderNavLink to={urls.offerRequests}>
        Tarjouspyynnöt
        {offerRequests.count > 0 && (
          <LinkBadge colors="primary">{offerRequests.count}</LinkBadge>
        )}
      </HeaderNavLink>
      <HeaderNavLink to={urls.tasks}>
        Tehtävät
        {!!openTasksCount && (
          <LinkBadge colors="primary">{formatNumber(openTasksCount)}</LinkBadge>
        )}
      </HeaderNavLink>
      <HeaderNavLink to={`${urls.contractors}${urls.new}`}>
        Uusi urakoitsija
      </HeaderNavLink>
      <HeaderNavLink to={urls.profile}>Profiili</HeaderNavLink>
    </>
  );
};

const NavShadow = styled.div`
  height: 30px;

  opacity: 0;
  background: linear-gradient(to bottom, black, transparent);

  transition: opacity ${themeGet('transitions.fast')};

  pointer-events: none;

  ${({menuIsOpen}) =>
    menuIsOpen &&
    css`
      opacity: 0.3;
    `}

  @media ${NAV_BREAKPOINT} {
    display: none;
  }
`;

const NavSpacer = styled.div`
  height: 10px;

  background: ${themeGet('colors.main')};

  @media ${NAV_BREAKPOINT} {
    display: none;
  }
`;

const StyledNavLinks = styled.nav`
  position: absolute;
  /* Adjust this when nav height changes */
  top: -275px;
  z-index: 20;
  width: 100%;

  display: flex;
  flex-direction: column;

  visibility: hidden;

  ${({menuIsOpen}) =>
    menuIsOpen &&
    css`
      top: 0;

      visibility: visible;
    `}

  transition: top ${themeGet('transitions.fast')};

  @media ${NAV_BREAKPOINT} {
    position: static;

    flex-direction: row;

    visibility: visible;
  }
`;

const NavLinks = withPerspective(({perspective, ...rest}) => {
  const {menuIsOpen} = useMenuState();

  return (
    <StyledNavLinks menuIsOpen={menuIsOpen} {...rest}>
      {perspective === null && <NavLinksGuest />}
      {perspective === 'client' && <NavLinksClient />}
      {perspective === 'contractor' && <NavLinksContractor />}
      {perspective === 'admin' && <NavLinksAdmin />}
      {perspective === 'salesAgent' && <NavLinksSalesAgent />}
      <NavSpacer />
      <NavShadow menuIsOpen={menuIsOpen} />
    </StyledNavLinks>
  );
});

const HeaderNav = (props) => (
  <div
    css={css`
      position: relative;
    `}
  >
    <NavLinks {...props} />
  </div>
);

export default HeaderNav;
