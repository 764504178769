import React, {lazy, Suspense} from 'react';

import refreshOnImportFailure from './refreshOnImportFailure';
import ScriptErrorBoundary from './ScriptErrorBoundary';
import DataErrorBoundary from './DataErrorBoundary';
import ErrorDisplay from '../layout/ErrorDisplay';

const ErrorBoundaries = ({children}) => (
  <ScriptErrorBoundary display={(error) => <ErrorDisplay errors={[error]} />}>
    <DataErrorBoundary display={(errors) => <ErrorDisplay errors={errors} />}>
      {children}
    </DataErrorBoundary>
  </ScriptErrorBoundary>
);

const roif = refreshOnImportFailure;

const lazyLoadRoute = (componentName) => {
  const LazyComponent = lazy(() => roif(import(`../routes/${componentName}`)));

  return (
    <ErrorBoundaries>
      <Suspense fallback="">
        <LazyComponent />
      </Suspense>
    </ErrorBoundaries>
  );
};

export {lazyLoadRoute};
