import React, {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {captureException, captureMessage} from '@sentry/browser';
import {gql} from '@apollo/client';
import {getUnixTime} from 'date-fns';

import {deserializeDate} from './dataFormatters';
import {urls} from './localization';
import useScript from '../hooks/useScript';

const dateToUnixTime = (date) => {
  if (date === null) return null;
  return getUnixTime(date);
};

const logError = (error) => {
  console.error(error);
  captureException(error);
};

const logMessage = (message) => {
  console.error(message);
  captureMessage(message);
};

const intercomScriptUrl =
  'https://widget.intercom.io/widget/' + process.env.REACT_APP_INTERCOM_APP_ID;

const intercomIdentityQuery = gql`
  query IntercomIdentity {
    viewer {
      id
      createdAt
      type
      email
      contractor {
        id
        createdAt
        name
        defaultContactName
        defaultContactPhone
      }
    }
  }
`;

const IntercomUpdater = () => {
  const result = useQuery(intercomIdentityQuery);

  const data = result?.data || null;
  const error = result?.error || null;
  const viewer = data?.viewer || null;
  const userId = viewer?.id || null;
  const userCreatedAt = deserializeDate(viewer?.createdAt);
  const userType = viewer?.type || null;
  const userEmail = viewer?.email || null;

  const contractor = viewer?.contractor || null;
  const contractorId = contractor?.id || null;
  const contractorCreatedAt = deserializeDate(contractor?.createdAt);
  const contractorName = contractor?.name || null;
  const contractorDefaultContactName = contractor?.defaultContactName || null;
  const contractorDefaultContactPhone = contractor?.defaultContactPhone || null;

  const {pathname} = useLocation();
  const isAllowedLocation = !pathname.includes(urls.messages);
  const shouldEnableIntercom = userType === 'contractor' && isAllowedLocation;

  const intercomIsBooted = useRef(false);
  const currentIntercomUserId = useRef(null);

  useEffect(() => {
    if (error !== null) {
      logError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error === null]);

  useEffect(() => {
    if (!window.Intercom) return;

    if (shouldEnableIntercom) {
      const intercomSettings = {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        email: userEmail,
        user_id: userId,
        created_at: dateToUnixTime(userCreatedAt),
        name: contractorDefaultContactName,
        phone: contractorDefaultContactPhone,
        company: {
          company_id: contractorId,
          name: contractorName,
          created_at: dateToUnixTime(contractorCreatedAt),
        },
        custom_launcher_selector: '#intercom',
      };

      if (intercomIsBooted.current) {
        if (currentIntercomUserId.current === userId) {
          window.Intercom('update', intercomSettings);
        } else {
          window.Intercom('shutdown');
          window.Intercom('boot', intercomSettings);
        }
      } else {
        window.Intercom('boot', intercomSettings);
      }

      intercomIsBooted.current = true;
      currentIntercomUserId.current = userId;
    } else if (intercomIsBooted.current) {
      window.Intercom('shutdown');

      intercomIsBooted.current = false;
      currentIntercomUserId.current = null;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, pathname]);

  return null;
};

const Intercom = () => {
  if (!process.env.REACT_APP_INTERCOM_APP_ID) return null;

  // Whether this component is bailed out of early is a build-time constant
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isLoaded = useScript(intercomScriptUrl);

  if (!isLoaded) return null;
  if (!window.Intercom) {
    logMessage(
      'Intercom script isLoaded === true but window.Intercom is falsy'
    );
    return null;
  }
  return <IntercomUpdater />;
};

export default Intercom;
