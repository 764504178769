import {useState, useEffect} from 'react';

const requestCallback = (func) => {
  try {
    if (window.requestIdleCallback && window.cancelIdleCallback) {
      const handle = window.requestIdleCallback(func);
      return () => window.cancelIdleCallback(handle);
    }
    const handle = window.setTimeout(func, 1000);
    return () => window.clearTimeout(handle);
  } catch (_) {}
  return () => {};
};

const cancelCallback = (cancelFunc) => {
  try {
    cancelFunc();
  } catch (_) {}
};

const WhenIdle = ({children}) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    let didCancel = false;

    const handle = requestCallback(() => {
      if (didCancel) return;
      setIsReady(true);
    });

    return () => {
      didCancel = true;
      cancelCallback(handle);
    };
  }, []);

  if (!isReady) return null;
  return children;
};

export default WhenIdle;
