import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
} from 'react';
import {v4 as uuidv4} from 'uuid';
import {captureException} from '@sentry/browser';

const ErrorContext = createContext([() => {}, () => {}]);

const DataError = ({error, children = null}) => {
  const [setError, removeError] = useContext(ErrorContext);

  useEffect(() => {
    const errorId = uuidv4();
    setError(errorId, error);
    return () => removeError(errorId);
  }, [error, setError, removeError]);

  return children;
};

const DataErrorBoundary = ({display = () => null, children}) => {
  const [errors] = useState(new Map());
  const [hasErrors, setHasErrors] = useState(false);

  const handleError = (error) => {
    console.error(error);
    captureException(error);
  };

  const setError = (key, value) => {
    errors.set(key, value);
    setHasErrors(errors.size > 0);
    handleError(value);
  };
  const removeError = (key) => {
    errors.delete(key);
    setHasErrors(errors.size > 0);
  };
  const contextValue = useMemo(
    () => [setError, removeError], // eslint-disable-next-line
    [
      /*setError, removeError*/
    ]
  );

  // Note: this doesn't update when errors changes
  const displayRender = hasErrors && display([...errors.values()]);
  const childrenIsFunction = typeof children === 'function';

  return (
    <>
      {!childrenIsFunction && displayRender}
      <ErrorContext.Provider value={contextValue}>
        {childrenIsFunction ? children(displayRender) : children}
      </ErrorContext.Provider>
    </>
  );
};

export default DataErrorBoundary;
export {DataError};
