const removeTypenames = (obj) => {
  if (Array.isArray(obj)) {
    const newArr = obj.map(removeTypenames);
    for (let i = 0; i < obj.length; i++) {
      if (!Object.is(obj[i], newArr[i])) {
        obj = newArr;
        break;
      }
    }
  } else if (typeof obj === 'object' && obj !== null) {
    for (const [key, value] of Object.entries(obj)) {
      if (key === '__typename') {
        const {__typename, ...rest} = obj;
        obj = rest;
      } else if (typeof value === 'object' && value !== null) {
        const newValue = removeTypenames(value);
        if (!Object.is(newValue, value)) {
          obj = {...obj, [key]: newValue};
        }
      }
    }
  }
  return obj;
};

export default removeTypenames;
