const scripts = new Map();
let callbackId = 0;

const createScriptEntry = (url, appendCallback) => {
  let resolver;
  let rejecter;
  const promise = new Promise((resolve, reject) => {
    resolver = resolve;
    rejecter = reject;
  });

  const entry = {
    promise,
    isResolved: false,
    isRejected: false,
  };

  const handleLoad = (...args) => {
    entry.isResolved = true;
    resolver(...args);
  };
  const handleError = (...args) => {
    entry.isRejected = true;
    rejecter(...args);
  };

  const tag = document.createElement('script');
  tag.type = 'text/javascript';
  tag.async = false;

  let theUrl = url;
  if (appendCallback) {
    const callbackName = `loadScriptCallback${callbackId++}`;
    window[callbackName] = handleLoad;
    theUrl += callbackName;
  } else {
    tag.addEventListener('load', handleLoad);
  }

  tag.addEventListener('error', handleError);

  tag.src = theUrl;
  document.body.appendChild(tag);
  return entry;
};

const loadScript = async (url, appendCallback = false) => {
  if (!scripts.has(url)) {
    scripts.set(url, createScriptEntry(url, appendCallback));
  }
  return scripts.get(url).promise;
};

const scriptIsLoaded = (url) => {
  if (!scripts.has(url)) return false;
  return scripts.get(url).isResolved;
};

export default loadScript;
export {scriptIsLoaded};
