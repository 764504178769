import {ApolloClient, ApolloLink, InMemoryCache} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {RetryLink} from '@apollo/client/link/retry';
import {setContext} from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import {captureException, withScope as withSentryScope} from '@sentry/browser';

import {getSessionToken} from '../utils/session';

// Don't retry mutations for now
const shouldRetry = (_, operation) => {
  let definitions;
  try {
    definitions = operation.query.definitions;
  } catch {
    return false;
  }
  if (!Array.isArray(definitions)) return false;
  return !definitions.some((definition) => {
    if (typeof definition !== 'object' || definition === null) {
      return false;
    }
    const {operation} = definition;
    return operation === 'mutation';
  });
};

const errorLink = onError((props) => {
  const {graphQLErrors, networkError} = props;
  if (graphQLErrors)
    graphQLErrors.forEach(({message, locations, path}) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const retryLink = new RetryLink({
  attempts: {
    retryIf: (error, operation) => {
      const willRetry = shouldRetry(error, operation);

      if (willRetry) {
        withSentryScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('context', 'Retrying');
          scope.setExtra('operation', operation);
          captureException(error);
        });
      }

      return willRetry;
    },
  },
});

const authLink = setContext((_, {headers: originalHeaders}) => {
  const headers = {...originalHeaders};
  const token = getSessionToken();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return {headers};
});

let serverUrl = process.env.REACT_APP_SERVER_URL;
if (process.env.NODE_ENV === 'development') {
  // Hack to support development on external devices using a LAN IP address
  serverUrl = serverUrl.replace('localhost', document.location.hostname);
}

const uploadLink = createUploadLink({
  uri: serverUrl,
  credentials: 'omit',
});

const link = ApolloLink.from([errorLink, retryLink, authLink, uploadLink]);

const cache = new InMemoryCache({
  typePolicies: {
    OfferRequest: {
      fields: {
        location: {
          merge(existing, incoming, {mergeObjects}) {
            return mergeObjects(existing, incoming);
          },
        },
      },
    },
    OfferRequestFilter: {
      fields: {
        location: {
          merge(existing, incoming, {mergeObjects}) {
            return mergeObjects(existing, incoming);
          },
        },
      },
    },
    Order: {
      fields: {
        finalPrice: {
          merge(existing, incoming, {mergeObjects}) {
            return mergeObjects(existing, incoming);
          },
        },
      },
    },
    OfferJob: {
      fields: {
        price: {
          merge(existing, incoming, {mergeObjects}) {
            return mergeObjects(existing, incoming);
          },
        },
      },
    },
    ContractorOfferRequest: {
      keyFields: ['contractorId', 'offerRequestId'],
    },
    SupportCase: {
      keyFields: ['contractorId'],
    },
  },
});

const apolloClient = new ApolloClient({link, cache});

export {apolloClient};
