import {useState, useEffect} from 'react';
import loadScript, {scriptIsLoaded} from '../utils/loadScript';

const useScript = (url, ...rest) => {
  const [isLoaded, setIsLoaded] = useState(() => scriptIsLoaded(url, ...rest));

  useEffect(() => {
    let didCancel = false;

    if (scriptIsLoaded(url, ...rest)) {
      setIsLoaded(true);
    } else {
      setIsLoaded(false);
      loadScript(url, ...rest).then(() => {
        if (didCancel) return;
        setIsLoaded(true);
      });
    }

    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line
  }, [url, ...rest]);

  return isLoaded;
};

export default useScript;
