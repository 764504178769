const space = [
  0,
  ...[...new Array(14).keys()].map(
    (key) => Math.round(Math.pow(1.61803398875, key)) + 'px'
  ),
];

const breakpoints = ['576px', '768px', '992px', '1200px'];

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

const radii = {
  tiny: '3px',
  small: '5px',
};

const fontSizes = [
  '1.2rem',
  '1.4rem',
  '1.6rem',
  '1.8rem',
  '2.0rem',
  '2.4rem',
  '3.0rem',
  '3.7rem',
  '4.4rem',
  '6.0rem',
  '7.2rem',
];

const fonts = {
  default:
    'Visuelt, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, ' +
    '"Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", ' +
    '"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  heading:
    'MarkOT, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, ' +
    '"Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", ' +
    '"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
};

const brandBlue = '#242267';
const accentBlue = '#4c71d2';
const accentOrange = '#fac25c';

const colorText = brandBlue;
const colorTextLight = 'white';
const colorTextBrown = '#40311f';

const colors = {
  brandBlue,
  accentBlue,
  accentOrange,

  background: '#ffffff',
  main: '#242267',
  mainLight: '#7372a0',
  secondary: accentOrange,
  secondaryLight: '#fff9ee',
  highlight1: '#4c71d2',
  highlight1Light: '#afbef8',
  highlight2: '#eff2fe',
  highlight2Light: '#f9faff',
  highlight3: '#a9a9bc',
  highlight3Light: '#dbdff0',
  error: '#ff819f',

  /* Vanhat */
  text: colorText,
  textLight: colorTextLight,
  textBrown: colorTextBrown,
  textVerySlightlyMuted: '#888D90',
  textSlightlyMuted: '#999ea3',
  textMuted: '#AAB3BB',
  textVeryMuted: '#ced4da',
  bg: 'white',
  bgHover: '#eff2fe' /* highlight2 */,
  bgActive: '#f9faff' /* highlight2Light */,
  borderThin: '#ced4da',
  primary: accentBlue,
  primaryText: colorTextLight,
  primaryFocusOutline: brandBlue,
  secondaryText: 'white',
  active: '#DFF0DB',
  activeText: '#226724',
  inactive: '#dbdff0' /* highlight3Light */,
  inactiveText: brandBlue,
  warning: '#ff819f' /* error */,
  warningText: 'white',
  positiveDark: '#1f9223',
  neutralDark: '#e29f40',
};

const colorStyles = {
  default: {
    color: colors.text,
    backgroundColor: colors.bg,
  },
  primary: {
    color: colors.text,
    backgroundColor: colors.secondary,
  },
  secondary: {
    color: colors.text,
    backgroundColor: colors.bgHover,
  },
  active: {
    color: colors.activeText,
    backgroundColor: colors.active,
  },
  inactive: {
    color: colors.inactiveText,
    backgroundColor: colors.inactive,
  },
  warning: {
    color: colors.warningText,
    backgroundColor: colors.warning,
  },
};

const hovers = {
  lighter: {
    '&:hover': {
      boxShadow: 'inset 0 0 100px 100px rgba(255, 255, 255, 0.1)',
    },
  },
  darker: {
    '&:hover': {
      boxShadow: 'inset 0 0 100px 100px rgba(0, 0, 0, 0.05)',
    },
  },
};

const buttons = {
  ...colorStyles,
  primary: {
    ...colorStyles.primary,
    fontWeight: 700,
    ...hovers.lighter,
  },
  secondary: {
    ...colorStyles.secondary,
    fontWeight: 'normal',
    ...hovers.darker,
  },
  active: {
    ...colorStyles.active,
    fontWeight: 700,
    ...hovers.darker,
  },
  warning: {
    ...colorStyles.warning,
    fontWeight: 700,
    ...hovers.darker,
  },
};

const borderWidths = {
  thin: '1px',
};

const borders = {
  thin: `${borderWidths.thin} solid ${colors.borderThin}`,
};

const shadows = {
  dropdown: '0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)',
  inset: '0 0 transparent',
  focusOutline: `0 0 0 2px ${colors.primaryFocusOutline}`,
  invalidOutline: `0 0 0 2px ${colors.error}`,
};

const durationsMs = {
  fast: 150,
  medium: 200,
};

const durations = {};
for (const [name, durationMs] of Object.entries(durationsMs)) {
  durations[name] = `${durationMs}ms`;
}

const timingFunctions = {
  default: 'ease',
};

const transitions = {};
for (const [name, duration] of Object.entries(durations)) {
  transitions[name] = `${duration} ${timingFunctions.default}`;
}

const theme = {
  space,
  breakpoints,
  radii,
  fontSizes,
  fonts,
  colors,
  colorStyles,
  buttons,
  borderWidths,
  borders,
  shadows,
  durationsMs,
  durations,
  timingFunctions,
  transitions,
};

export default theme;
