import React from 'react';
import {Navigate} from 'react-router-dom';

import {urls} from './utils/localization';
import {lazyLoadRoute} from './utils/routeUtils';

const salesAgentRoutes = [
  {path: `${urls.home}`, element: <Navigate replace to={urls.offerRequests} />},
  {
    path: `${urls.offerRequests}`,
    element: lazyLoadRoute('AdminOfferRequests'),
  },
  {
    path: `${urls.orders}/:id`,
    element: lazyLoadRoute('Order'),
  },
  {
    path: `${urls.offerRequests}/:offerRequestId${urls.messages}/:contractorId`,
    element: lazyLoadRoute('Conversation'),
  },
  {
    path: `${urls.offerRequests}/:id${urls.edit}`,
    element: lazyLoadRoute('EditOfferRequest'),
  },
  {
    path: `${urls.offerRequests}/:offerRequestId${urls.contractors}/:contractorId`,
    element: lazyLoadRoute('ViewOfferRequestContractor'),
  },
  {
    path: `${urls.offerRequests}/:id`,
    element: lazyLoadRoute('AdminViewOfferRequest'),
  },
  {path: `${urls.clients}/:id`, element: lazyLoadRoute('EditClient')},
  {path: urls.tasks, element: lazyLoadRoute('AdminTasks')},
  {
    path: `${urls.contractors}${urls.new}`,
    element: lazyLoadRoute('AdminNewContractor'),
  },
  {
    path: `${urls.contractors}`,
    element: <Navigate replace to={`${urls.contractors}${urls.new}`} />,
  },
  {path: urls.profile, element: lazyLoadRoute('AdminProfile')},
  {
    path: `*`,
    element: lazyLoadRoute('NotFound'),
    end: false,
    caseSensitive: false,
  },
];

export {salesAgentRoutes};
