import {urls} from './utils/localization';
import {lazyLoadRoute} from './utils/routeUtils';

const adminRoutes = [
  {
    path: `${urls.home}`,
    element: lazyLoadRoute('AdminHome'),
  },
  {
    path: `${urls.offerRequests}`,
    element: lazyLoadRoute('AdminOfferRequests'),
  },
  {
    path: `${urls.orders}/:id`,
    element: lazyLoadRoute('Order'),
  },
  {path: `${urls.orders}`, element: lazyLoadRoute('Orders')},
  {
    path: `${urls.offerRequests}/:offerRequestId${urls.messages}/:contractorId`,
    element: lazyLoadRoute('Conversation'),
  },
  {
    path: `${urls.offerRequests}/:id${urls.edit}`,
    element: lazyLoadRoute('EditOfferRequest'),
  },
  {
    path: `${urls.offerRequests}/:offerRequestId${urls.contractors}/:contractorId${urls.new}`,
    element: lazyLoadRoute('NewOffer'),
  },
  {
    path: `${urls.offerRequests}/:offerRequestId${urls.contractors}/:contractorId`,
    element: lazyLoadRoute('ViewOfferRequestContractor'),
  },
  {
    path: `${urls.offerRequests}/:id`,
    element: lazyLoadRoute('AdminViewOfferRequest'),
  },
  {
    path: `${urls.offerTemplates}${urls.new}`,
    element: lazyLoadRoute('NewOfferTemplate'),
  },
  {
    path: `${urls.offers}/:id${urls.edit}`,
    element: lazyLoadRoute('EditOffer'),
  },
  {
    path: `${urls.offerTemplates}/:id`,
    element: lazyLoadRoute('EditOfferTemplate'),
  },
  {path: `${urls.clients}/:id`, element: lazyLoadRoute('EditClient')},
  {path: urls.contractors, element: lazyLoadRoute('Contractors')},
  {
    path: `${urls.contractors}${urls.new}`,
    element: lazyLoadRoute('AdminNewContractor'),
  },
  {path: `${urls.contractors}/:id`, element: lazyLoadRoute('EditContractor')},
  {
    path: `${urls.offerRequestFilters}${urls.new}`,
    element: lazyLoadRoute('NewOfferRequestFilter'),
  },
  {
    path: `${urls.offerRequestFilters}/:id`,
    element: lazyLoadRoute('EditOfferRequestFilter'),
  },
  {
    path: urls.serviceFees,
    element: lazyLoadRoute('AdminServiceFees'),
  },
  {
    path: urls.serviceFeeInvoices,
    element: lazyLoadRoute('AdminServiceFeeInvoices'),
  },
  {
    path: urls.serviceFeeInvoiceTransactions,
    element: lazyLoadRoute('AdminServiceFeeInvoiceTransactions'),
  },
  {path: urls.reviews, element: lazyLoadRoute('AdminReviews')},
  {path: urls.tasks, element: lazyLoadRoute('AdminTasks')},
  {path: urls.support, element: lazyLoadRoute('AdminSupportCases')},
  {path: urls.jobTypes, element: lazyLoadRoute('AdminJobTypes')},
  {path: `${urls.jobTypes}/:id`, element: lazyLoadRoute('EditJobType')},
  {path: urls.profile, element: lazyLoadRoute('AdminProfile')},
  {
    path: `*`,
    element: lazyLoadRoute('NotFound'),
    end: false,
    caseSensitive: false,
  },
];

export {adminRoutes};
