import React from 'react';
import {Text} from 'rebass/styled-components';

import ContentContainer from '../primitives/ContentContainer';
import PageTitle from '../primitives/PageTitle';
import HeadingWrapper from '../primitives/HeadingWrapper';

const ErrorDisplay = ({unstyled = false}) =>
  !unstyled ? (
    <ContentContainer>
      <HeadingWrapper>
        <PageTitle>Tapahtui virhe</PageTitle>
      </HeadingWrapper>
      <Text>Pahoittelut, asiasta on ilmoitettu ylläpitäjille.</Text>
    </ContentContainer>
  ) : (
    <>
      <h1>Tapahtui virhe</h1>
      <p>Pahoittelut, asiasta on ilmoitettu ylläpitäjille.</p>
    </>
  );

export default ErrorDisplay;
