import {useState, useEffect} from 'react';

const useMedia = (query) => {
  const [, setUpdateCount] = useState(0);
  const forceUpdate = () => setUpdateCount((i) => i + 1);

  useEffect(() => {
    const handleChange = () => {
      forceUpdate();
    };

    const mql = window.matchMedia(query);
    mql.addListener(handleChange);

    return () => {
      mql.removeListener(handleChange);
    };
  }, [query]);

  return window.matchMedia(query).matches;
};

export default useMedia;
